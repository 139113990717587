


import { Component } from "vue-property-decorator";
import { BASE_API_URL,EventBus } from "@/config";
import Axios from "axios";
import AddComment from "@/views/AddComment.vue";
import { initialDisclosure } from "@/models/tasks.model";
import CreditAuthorization from "@/views/CreditAuthorizationInTask.vue";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";


@Component({ components: { AddComment, CreditAuthorization } })
export default class InitialDisclosureComponent extends mixins(PipeLineSocketMixin, CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  private pipeLineHeaderData: any = null;
  private initialDisclosureData: initialDisclosure = new initialDisclosure();
  public loanTxnId = null;
  private leStatus = null;
  private isLeApproved = false;
  private loanVersion = '';
  public taskNameForRoom = 'initial-disclosure';

  private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Initial Disclosures", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/InitialDisclosures.mp4' });
  }
  private async fetchInitialDisclosureDetails() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchInitialDisclosureDetails",
        { loanTxnId: this.loanTxnId });
      this.pipeLineHeaderData = response.data.pipelineHeader;
      this.initialDisclosureData = response.data.initialDisclosureData;
      this.isLeApproved = this.initialDisclosureData.loanEstimate.status==='uploaded';
      this.loanVersion = this.pipeLineHeaderData.loanVersion;
    } catch (error) {
      console.log(error);
    }
  }


  private async submit(ifSave) {
    try {

      /**
       * Validating conditions here
       */
      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment['validateComment'](ifSave);
      /**
       * If clicked on Complete then validate all fields otherwise
       * check conditions if not validate either both then return;
       */
      if ((ifSave ? false : !await this.$validator.validateAll() ) || !validComment)
        return;
      if(!ifSave
         && this.initialDisclosureData.loanEstimate.documents.length>0
         && this.initialDisclosureData.loanEstimate.status!='approved'){
        if(!this.initialDisclosureData.loanEstimate.documentUploadStatus) {
          this.$set(this.initialDisclosureData.loanEstimate,'documentUploadStatus', 'Please save to upload document and notify to broker to review' );
          return;
        }
      }
      this.$store.state.wemloLoader= true;

      let formData = new FormData();
      formData.append("loanTxnId", this.loanTxnId);
      formData.append("userType", JSON.stringify(this.$store.state.sessionObject.type));

      /**
       * Appending whether save or complete button is clicked here in formdata
       */
      formData.append("ifSave", ifSave);
      /**
       * Appending options data here in formdata
       */
      formData.append("options", JSON.stringify(this.initialDisclosureData.options));

      /**
       * Appending lender disclosure document data here in formdata
       */

         if (
        this.initialDisclosureData.lenderInitialDisclosure &&
        !this.initialDisclosureData.lenderInitialDisclosure.hasOwnProperty("path")
      )
        this.initialDisclosureData.lenderInitialDisclosure.forEach(file => {
          formData.append("lenderInitialDisclosureDocument", file);
        });
      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.initialDisclosureData.commentData.comment,
        showCommentToBroker: this.initialDisclosureData.commentData.showCommentToBroker
      }
      if (this.initialDisclosureData.commentData.commentAttachment.length > 0 && !this.initialDisclosureData.commentData.commentAttachment[0].hasOwnProperty("path"))
        this.initialDisclosureData.commentData.commentAttachment.forEach(file => { formData.append("commentAttachment", file) });
      else if (this.initialDisclosureData.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.initialDisclosureData.commentData.commentAttachment;

      formData.append("commentData", JSON.stringify(commentObj));
      
      // if(this.initialDisclosureData.creditAuthorization.status=='uploaded'||this.initialDisclosureData.creditAuthorization.status=='byPassed'){
      //   if(this.initialDisclosureData.creditAuthorization.status=='uploaded' && !this.initialDisclosureData.creditAuthorization.documents[0].hasOwnProperty("path")){
      //     this.initialDisclosureData.creditAuthorization.documents.forEach(file => {
      //       formData.append("creditAuthorizationDocument", file);
      //     });
      //   }
      //   if(this.initialDisclosureData.creditAuthorization.status=='byPassed'&&this.initialDisclosureData.creditAuthorization.byPassComment.length>0){
      //     formData.append("creditAuthorizationComment", JSON.stringify(this.initialDisclosureData.creditAuthorization.byPassComment));
      //   }
      // }
      /**
       * Updating Loan Estimate Data here
       */
      if(this.initialDisclosureData.loanEstimate.status=='uploaded'||this.initialDisclosureData.loanEstimate.status=='byPassed'){
        if(this.initialDisclosureData.loanEstimate.status=='uploaded' && !this.initialDisclosureData.loanEstimate.documents[0].hasOwnProperty("path")){
          this.initialDisclosureData.loanEstimate.documents.forEach((file,i) => {
            formData.append("loanEstimateDocument", file);
          });
        }
        if(this.initialDisclosureData.loanEstimate.status=='byPassed'){
          formData.append("loanEstimateComment", JSON.stringify(this.initialDisclosureData.loanEstimate.byPassComment));
        }
      }

      /**
       * Merging Credit Authorization Data here
       */
      if(this.initialDisclosureData.creditAuthorization.status=='uploaded'||this.initialDisclosureData.creditAuthorization.status=='byPassed'){
        if(this.initialDisclosureData.creditAuthorization.status=='uploaded' && !this.initialDisclosureData.creditAuthorization.documents[0].hasOwnProperty("path")){
          this.initialDisclosureData.creditAuthorization.documents.forEach(file => {
            formData.append("creditAuthorizationDocument", file);
          });
        }
        if(this.initialDisclosureData.creditAuthorization.status=='byPassed'&&this.initialDisclosureData.creditAuthorization.byPassComment.length>0){
          formData.append("creditAuthorizationComment", JSON.stringify(this.initialDisclosureData.creditAuthorization.byPassComment));
        }
      }
      /**
       * Finally sending request to server with formdata in body
       */
      let response = await Axios.post(BASE_API_URL + "wemloprocessor/saveInitialDisclosureData", formData);
      this.$snotify[response.data.status == 200 ? "success" : response.data.status == 403 ? "info" : "error"](response.data.message);
      if (response.data.status == 200) {
        this.saveEvent();
      }
      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;    

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  public removeInfectedFile(filename: string): void {
    // check if comment attachment contains infected filename
    if (
      this.initialDisclosureData.commentData.commentAttachment.length > 0 &&
      !this.initialDisclosureData.commentData.commentAttachment[0].hasOwnProperty("path")
    )
    {
      this.initialDisclosureData.commentData.commentAttachment = this.initialDisclosureData.commentData.commentAttachment.filter(file => (file.name !== filename));
    }
    // check if lenderInitialDisclosure contains infected filename
    if (
      this.initialDisclosureData.lenderInitialDisclosure &&
      !this.initialDisclosureData.lenderInitialDisclosure.hasOwnProperty("path")
    ) {
      this.initialDisclosureData.lenderInitialDisclosure = this.initialDisclosureData.lenderInitialDisclosure.filter(file => (file.name !== filename));
    }

    // check if credit authorization contains infected filename
    if(this.initialDisclosureData.creditAuthorization.status=='uploaded'||this.initialDisclosureData.creditAuthorization.status=='byPassed'){
      if(this.initialDisclosureData.creditAuthorization.status=='uploaded' && !this.initialDisclosureData.creditAuthorization.documents[0].hasOwnProperty("path")){
        const isCreditAuthFileInfected = this.initialDisclosureData.creditAuthorization.documents.find(file => (file.name === filename));
        this.initialDisclosureData.creditAuthorization.documents = this.initialDisclosureData.creditAuthorization.documents.filter(file => (file.name !== filename));
        if (isCreditAuthFileInfected) this.initialDisclosureData.creditAuthorization.status = null;
      }
    }
  }


  async uploadFile(event) {
    if (event.target.files.length > 0) {
      Object.values(event.target.files).forEach(element => {
          this.initialDisclosureData.lenderInitialDisclosure.push(element);
      });
    }
  }
  public displayUploadFiles(file, viewFileName) {
    this.$refs.comment["displayCommentAttachment"](file, true, viewFileName);
  }

  disabledPropertiesOfLoanEstimate(){
    let disabled  =false
    if(this.initialDisclosureData.loanEstimate.documentUploadStatus=='Document approved' || ( this.initialDisclosureData.loanEstimate.hasOwnProperty('documents') 
    &&this.initialDisclosureData.loanEstimate.documents.length>0)){
      disabled = true
    }else if(this.initialDisclosureData.loanEstimate.status == 'rejected' ){
      disabled = false
    }
    return disabled
  }
 uploadLoanEstimate(event){
    try {
      if (event.target.files.length > 0) {
        this.initialDisclosureData.loanEstimate.documents = [];
        this.initialDisclosureData.loanEstimate.documents.push(event.target.files[0]);
        this.changeStatusOfLoanEstimate('uploaded')
      }
    } catch (error) {
      console.log(error);
    }
  }

  changeStatusOfLoanEstimate(from){
    try {
      if(from =='byPassed'){
        this.initialDisclosureData.loanEstimate.status = 'byPassed';
        this.initialDisclosureData.loanEstimate.documents = [];
      }
      else if(from =='uploaded'){
        this.initialDisclosureData.loanEstimate.status = 'uploaded';
        this.initialDisclosureData.loanEstimate.byPassComment = null;
      }
      delete this.initialDisclosureData.loanEstimate.rejectReason;
      delete this.initialDisclosureData.loanEstimate.documentUploadStatus;
    } catch (error) {
      console.log(error);
    }
  }
  checkStatusOfLoanEstimateUploadButton(){
    let returnVal = false;
    if(!this.initialDisclosureData.loanEstimate.status|| ((this.initialDisclosureData.loanEstimate.status=='byPassed' || this.initialDisclosureData.loanEstimate.status=='rejected') )
    &&(!this.initialDisclosureData.loanEstimate.documentUploadStatus||this.initialDisclosureData.loanEstimate.documentUploadStatus=='Document rejected'))
    returnVal =  true;
    else if(this.initialDisclosureData.loanEstimate.hasOwnProperty('documents') && this.initialDisclosureData.loanEstimate.documents.length>0&&!this.initialDisclosureData.loanEstimate.documents[0].hasOwnProperty('path'))
    returnVal =  false;
    else if(this.initialDisclosureData.loanEstimate.status && this.initialDisclosureData.loanEstimate.status == 'approved')
    returnVal =false
    return returnVal;
  }

  showRejectCommentAndAttachments(comment){
    this.$refs.comment["displayComment"](comment, this.initialDisclosureData.loanEstimate['rejectAttachment']);
  }


  async beforeMount() {
    this.loanTxnId = this.$route.query.id;
    await this.fetchInitialDisclosureDetails();
  }
}
