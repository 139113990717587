











































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HollowDotsSpinner } from "epic-spinners";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";

@Component({ components: { HollowDotsSpinner } })
export default class Comments extends Vue {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  @Prop()
  public prevComment;
  @Prop()
  public prevFiles;
  @Prop()
  public preIsActiveComment;

  @Prop()
  public id;

   @Prop()
  public task;

  public comment = null;
  public files: any = [];
  public countFile = 0;
  public isActiveComment = false;
  public duplicateIsActiveComment = false;
  public dotLoader = false;
  public fileIndex = 0;
  public fileArray = [];
  public showPrev = false;
  public showNext = false;
  public fileChange = false;
  public commentChange = false;
  public fileToDisplay = null;
  public fileType = "";
  public allFileTypes = [];
  public deleteSamples = false;
  public samplesIndex = null;
  public samplesType = "";
  public displayFileType = "";
  public displayingFileHeader = null;
  public localFiles = [];
  @Watch("prevComment")
  showComment(comment) {
    this.comment = comment;
  }
  @Watch("prevFiles")
  showFiles(files) {
    this.files = files;
    this.countFile = this.files.length;
  }
  @Watch("preIsActiveComment")
  showIsActiveComment(isActive) {
    if (!this.duplicateIsActiveComment) {
      this.isActiveComment = isActive;
      this.duplicateIsActiveComment = true;
    }
  }
  public fileUploads(event) {
    this.files = [];
    if (event.target.files.length == 0) return;
    Object.values(event.target.files).forEach(e => {
      this.files.push(e);
      this.localFiles = this.files;
    });
    if (this.files.length > 0) {
      this.fileChange = true;
    }
    this.countFile = this.files.length;
    this.$emit("fileUpload", this.files);
  }
  public displayDocument(allFiles) {
    let files = allFiles;
    if (this.localFiles.length > 0) {
      this.deleteSamples = true;
    }
    this.showPrev = false;
    if (files.length > 1) {
      this.showNext = true;
    }
    this.fileArray = [];
    if (!this.fileChange) {
      files.forEach((file, i) => {
        this.fileArray.push(file.path);
      });
      this.fileToDisplay = this.fileArray[0];
      let ext = this.fileToDisplay
        .split(".")
        .pop()
        .toLowerCase();
      this.fileType = ext;
    } else {
      allFiles.forEach((e, i) => {
        this.fileArray.push(URL.createObjectURL(e));
        let type = e.name.split(".");
        this.allFileTypes.push(type[type.length - 1].toLowerCase());
        this.fileToDisplay = this.fileArray[0];
        this.fileType = this.allFileTypes[0];
      });
    }
    this.fileIndex = 0;
    this.dotLoader = true;
    this.$modal.show("showFiles");
  }

  public changeImageIndex(check) {
    this.dotLoader = true;
    if (check == "next") {
      this.fileIndex++;
      this.showPrev = true;
      this.fileToDisplay = this.fileArray[this.fileIndex];
      if (!this.fileChange) {
        this.fileType = this.fileToDisplay
          .split(".")
          .pop()
          .toLowerCase();
      } else {
        this.fileType = this.allFileTypes[this.fileIndex];
      }
      if (this.fileArray.length == this.fileIndex + 1) {
        this.showNext = false;
      }
    }
    if (check == "previous") {
      this.fileIndex--;
      this.showNext = true;
      this.fileToDisplay = this.fileArray[this.fileIndex];
      let length = this.fileToDisplay.length;
      if (!this.fileChange) {
        this.fileType = this.fileToDisplay
          .split(".")
          .pop()
          .toLowerCase();
      } else {
        this.fileType = this.allFileTypes[this.fileIndex];
      }

      if (this.fileIndex == 0) {
        this.showPrev = false;
      }
    }
  }
  public hideDisplayFileModal() {
    this.deleteSamples = false;
    this.fileToDisplay = null;
    this.displayFileType = null;
    this.dotLoader = false;
    this.$modal.hide("showFiles");
  }
  // delete attachmen

  public deleteAttachment() {
    let fileIndex = this.fileIndex;
    if (this.files.length == 1) {
      let commentAttachment: any = this.$refs.commentAttachment;
      commentAttachment.value = null;
    }
    this.files.splice(fileIndex, 1);
    if (this.files.length == 1) {
      this.showNext = false;
      this.showPrev = false;
    }
    this.localFiles = this.files;
    this.displayDocument(this.localFiles);
    if (this.files.length == 0) {
      this.$modal.hide("showFiles");
    }
  }
}
