

import { Component, Vue } from "vue-property-decorator";
import AddComment from "@/views/AddComment.vue";
import Axios from "axios";
import { BASE_API_URL } from "../../../../config";

@Component({ components: {  } })
export default class QualityAssuranceComponent extends Vue {
  public pipeLineHeaderData: any = {};
  public borrowerInfo = [];
  public selectedFilter: string = 'Active'
  public async getBorrowerListForCompliance() {
    try {
    this.$store.state.wemloLoader = true;
    let loanTxnId = this.$route.query.id;
      let response = await Axios.get(
        BASE_API_URL + "compliance/getBrokerLoanForCompliance",
        {
          params: {
            brokerId: this.$brokerId,
            loanTxnId,
            type: this.userType,
            userId: this.$userId,
            year: '',
            filterBy: this.selectedFilter,
            options: {}
          }
        }
      );
      this.borrowerInfo = [];
      this.borrowerInfo.push(...response.data.borrowerInfo);
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

   searchYear(e) {
    let {value} = e.target;
    this.selectedFilter = value;
    this.getBorrowerListForCompliance();
  }

  mounted() {
    this.getBorrowerListForCompliance();
  }

  get userType() {
    return this.$store.state.sessionObject.type;
  }

}
