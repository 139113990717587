

import { Component, Vue, Prop} from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import ComplianceList from "@/views/compliance/ComplianceList.vue";
import saveAs from "save-as";
import _ from "lodash";

@Component({ components: { ComplianceList } })

export default class ComplianceCheckerComponent extends Vue {
  public userId = this.$brokerId;
  public complianceList: any[] = [];
  public loanTxnId = null;
  
  @Prop({default: false}) public loanIsLocked: boolean;
  
  public async getComplianceList() {
    this.$store.state.wemloLoader = true;
    this.$emit('fetchComplianceStatus')
    let response = await Axios.post(
      BASE_API_URL + "onBoarding/getComplianceList",
      {
        loanTxnId: this.$route.query.id,
      },
      {
      }
    );
    this.$store.state.wemloLoader = false;
    this.complianceList = response.data || [];
  }

  saveCompliance(){
    let $saveBorrowerCompliancePackage = _.get(this.$refs, 'complianceList.saveBorrowerCompliancePackage')
    if($saveBorrowerCompliancePackage){
      this.$refs['complianceList']['saveBorrowerCompliancePackage']();
    }
  }
  
  async saveBorrowerCompliance(selectedPackage){
    let $package = this.$refs['complianceList'] as any;
    this.$store.state.wemloLoader = true;
    let response = await Axios.post(
      BASE_API_URL + "compliance/saveBorrowerCompliancePackage",
      {
        loanTxnId: this.$route.query.id,
        compliancePackage : selectedPackage
      });
    if(response.status == 200){
      this.$snotify.success("Compliance Package Updated.");
    }else{
      this.$snotify.error("Error Updating Compliance Package.");
    }
    this.$store.state.wemloLoader = false;
  }

  public async downloadCompliance() {
    this.$store.state.wemloLoader = true;
    let loanTxnId = this.$route.query.id;
    let response = await Axios.post(
      BASE_API_URL + "compliance/downloadCompliancePackage",
      { loanTxnId: loanTxnId, selections: false },
      {responseType: "blob" }
    );
    if (response.status == 200 && response.data) {
        saveAs(response.data, loanTxnId + `.zip`);
      } else {
        this.$snotify.clear();
        this.$snotify.error("No Data Found");
      }
    this.$store.state.wemloLoader = false;
  }

  async mounted() {
    this.loanTxnId = this.$route.query.id;
    await this.getComplianceList();
  }
}
