


import { Component } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus} from "@/config";
import AddConditions from "@/views/AddConditions.vue";
import { ConditionalApproval } from "@/models/tasks.model";
import Datepicker from "vuejs-datepicker";
import AddComment from "@/views/AddComment.vue";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin";
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";

@Component({ components: { AddConditions, Datepicker, AddComment } })
export default class ConditionalApprovalComponent extends mixins(PipeLineSocketMixin, CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public loanTxnId: any = null;
  public pipeLineHeaderData: string = null;
  public conditionalApprovalData: ConditionalApproval = new ConditionalApproval();
  public taskNameForRoom = 'conditional-approval';

  private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Conditional Approval", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/ConditionalApproval.mp4' });
  }
  public async fetchConditionalApprovalDetails() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchConditionalApprovalDetails",
        { loanTxnId: this.loanTxnId });

      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.conditionalApprovalData = response.data.conditionalApprovalData;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public async submit(ifSave) {
    try {
      /**
       * Validating conditions here
       */
      ifSave = ifSave == "Save" ? true : false;

      let validConditions = await this.$refs.conditions["validate"](ifSave);
      let validComment = await this.$refs.comment["validateComment"](ifSave);

      if (!validConditions) { return; }

      if (!ifSave && (!(await this.$validator.validateAll()) || !validComment || !validConditions)) {
        return;
      }

      /**
       * Taking Formdata for sending files and data
       */
      this.$store.state.wemloLoader = true;
      let formData = new FormData();
      /**
       * Merging Conditions here
       */
      this.conditionalApprovalData.borrowerCondition.forEach((f, i) => {
        if (f.samples.length > 0 && !f.samples[0].hasOwnProperty("path")) {
          f.samples.forEach(file => formData.append("borrowerCondition" + i, file));
        }
      });
      this.conditionalApprovalData.thirdPartyCondition.forEach((f, i) => {
        if (f.samples.length > 0 && !f.samples[0].hasOwnProperty("path")) {
          f.samples.forEach(file => formData.append("thirdPartyCondition" + i, file));
        }
      });
      formData.append(
        "borrowerCondition",
        JSON.stringify(
          this.conditionalApprovalData.borrowerCondition.map(b => {
            const { fileHistory, ...withoutHistory } = b;
            return { ...withoutHistory, samples: []};
          })
        )
      );
      formData.append(
        "thirdPartyCondition",
        JSON.stringify(
          this.conditionalApprovalData.thirdPartyCondition.map(t => {
            const { fileHistory, ...withoutHistory } = t;
            return { ...withoutHistory, samples: []};
          })
        )
      );
      //  Appending LoanTxnId in formData
      formData.append("loanTxnId", this.loanTxnId);
      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.conditionalApprovalData.commentData.comment,
        showCommentToBroker: this.conditionalApprovalData.commentData.showCommentToBroker
      }
      if (this.conditionalApprovalData.commentData.commentAttachment.length > 0 && !this.conditionalApprovalData.commentData.commentAttachment[0].hasOwnProperty("path"))
        this.conditionalApprovalData.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.conditionalApprovalData.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.conditionalApprovalData.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));
      /**
       * Merging the save or complete option here with stamp approval date
       */
      formData.append("options", JSON.stringify(this.conditionalApprovalData.options));

      if(this.conditionalApprovalData.updatedStampApprovalDate&&typeof this.conditionalApprovalData.updatedStampApprovalDate=='object')
      formData.append("updatedStampApprovalDate", this.conditionalApprovalData.updatedStampApprovalDate);
      formData.append("ifSave", ifSave);

  if (
        this.conditionalApprovalData.lenderApprovalOrCommitmentLetter &&
        !this.conditionalApprovalData.lenderApprovalOrCommitmentLetter.hasOwnProperty("path")
      )
        this.conditionalApprovalData.lenderApprovalOrCommitmentLetter.forEach(file => {
          formData.append("lenderApprovalOrCommitmentLetter", file);
        });

      /**
       * Sending request to server with formdata in body.
       */
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/saveConditionalApprovalData",
        formData);

      this.$snotify[response.data.status == 200 ? "success" : response.data.status == 403 ? "info" : "error"](response.data.message);

      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      if (response.data.status == 200) {
        this.saveEvent();
      }

      await this.fetchConditionalApprovalDetails();
      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  removeInfectedFile(filename: string): void {
      // check if borrower conditions contain infected filename
      this.conditionalApprovalData.borrowerCondition.forEach((f,i) => {
          this.filterSamplesIfInfected(f, filename);
      });

      // check if 3rd party conditions contain infected filename
      this.conditionalApprovalData.thirdPartyCondition.forEach((f, i) => {
          this.filterSamplesIfInfected(f, filename);
      });

      // check if comment attachment contains infected filename
      if (
          this.conditionalApprovalData.commentData.commentAttachment.length > 0 &&
          !this.conditionalApprovalData.commentData.commentAttachment[0].hasOwnProperty(
              "path"
          )
      )
      {
          this.conditionalApprovalData.commentData.commentAttachment = this.conditionalApprovalData.commentData.commentAttachment.filter(file => (file.name !== filename));
      }

      // check if clearToCloseData contains infected filename
      if (
          this.conditionalApprovalData.lenderApprovalOrCommitmentLetter &&
          !this.conditionalApprovalData.lenderApprovalOrCommitmentLetter.hasOwnProperty("path")
      ) {
          this.conditionalApprovalData.lenderApprovalOrCommitmentLetter = this.conditionalApprovalData.lenderApprovalOrCommitmentLetter.filter(file => (file.name !== filename));
      }
  }


    public uploadDocument(event, fileName) {
    try {
      if (event.target.files.length > 0) {
          Object.values(event.target.files).forEach(element => {
            this.conditionalApprovalData.lenderApprovalOrCommitmentLetter.push(element);
          });
       
      }
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * To display wemlo invoice files
   */
  public viewDocuments(file, fileName) {
    this.$refs.comment["displayCommentAttachment"](file, true, fileName);
  }

  public deleteDoc(obj) {
    this.conditionalApprovalData[obj.deleteData].splice(obj.index, 1);
  }

  public async deleteFile(obj) {
    let index = this.conditionalApprovalData[obj.deleteData].findIndex(
      doc => doc.path == obj.files
    );
    this.conditionalApprovalData[obj.deleteData].splice(index, 1);
    await this.conditionalApprovalDocumentDeleteFromCollection(
      index,
      obj.deleteData
    );
  }

  public async conditionalApprovalDocumentDeleteFromCollection(
    index,
    categoryName
  ) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/deleteDocumentByCategoryName",
        { loanTxnId: this.loanTxnId, index: index, categoryName: categoryName });
    } catch (error) {
      console.log(error);
    }
  }

  refreshDocumentStatusById(docId){
    let borrowerOrThirdPartyCondition = this.conditionalApprovalData.borrowerCondition.find(d=>d.documentId==docId)?'borrowerCondition':'thirdPartyCondition'
    let index = this.conditionalApprovalData[borrowerOrThirdPartyCondition].findIndex(d=>d.documentId==docId);
    this.conditionalApprovalData[borrowerOrThirdPartyCondition][index].hasApprovedToSubmit = true;
  }

  mounted() {
    this.loanTxnId = this.$route.query.id;
    this.fetchConditionalApprovalDetails();
  }
}
