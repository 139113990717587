




import {Component} from "vue-property-decorator";
import {ClearToClose} from "@/models/tasks.model";
import AddComment from "@/views/AddComment.vue";
import { BASE_API_URL,EventBus } from "../../../../config";
import Axios from "axios";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";

@Component({components: {AddComment} })
export default class ClearToCloseComponent extends mixins(PipeLineSocketMixin, CommonMixin) {

  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public clearToCloseData = new ClearToClose();
  public pipeLineHeaderData: any = {};
  public loanTxnId = null;
  public fileNames =null;
  public taskNameForRoom = 'clear-to-close';

  private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Clear To Close", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/CleartoClose.mp4' });
  }
  /**
   * API for fetching data of task and pipeline header.
   */
  public async fetchClearToCloseTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchClearToCloseTaskDetail",
        {loanTxnId: this.loanTxnId });

      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.clearToCloseData = response.data.clearToCloseData;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }


  /**
   * API for saving data of task.
   */
  public async submit(ifSave) {
    try {


      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment['validateComment'](ifSave);


        if (
        ifSave
          ? false
          : !(await this.$validator.validateAll()) || !validComment
      )
        return;
      /**
       * Taking Formdata for sending files and data
       */
      this.$store.state.wemloLoader = true;
      let formData = new FormData();


      // Appending loanTxnId here
      formData.append("loanTxnId", this.loanTxnId);
      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
      comment: this.clearToCloseData.commentData.comment,
        showCommentToBroker: this.clearToCloseData.commentData.showCommentToBroker
      }
      if (this.clearToCloseData.commentData.commentAttachment.length > 0 && !this.clearToCloseData.commentData.commentAttachment[0].hasOwnProperty("path"))
        this.clearToCloseData.commentData.commentAttachment.forEach(file => {
      formData.append("commentAttachment", file);
        });
      else if (this.clearToCloseData.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.clearToCloseData.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));


      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.clearToCloseData.options));
      // if (this.clearToCloseData.wemloInvoice && !this.clearToCloseData.wemloInvoice.hasOwnProperty('path'))
      //   formData.append( "wemloInvoice", this.clearToCloseData.wemloInvoice );

     if (
        this.clearToCloseData.creditReportInvoice &&
        !this.clearToCloseData.creditReportInvoice.hasOwnProperty("path")
      ){
        this.clearToCloseData.creditReportInvoice.forEach(file => {
          formData.append("creditReportInvoice", file);
        });
      }

       if (
        this.clearToCloseData.rateLock &&
        !this.clearToCloseData.rateLock.hasOwnProperty("path")
      ){
        this.clearToCloseData.rateLock.forEach(file => {
          formData.append("rateLock", file);
        });
      }

      /**
       * Finally sending request to server with formdata in body
       */

      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/clearToClose",
        formData);
      this.$snotify[response.data.status == 200 ? "success" : response.data.status == 403 ? "info": "error"](response.data.message);
      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;     

      if (response.data.status == 200) {
        this.saveEvent();
      }

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  public removeInfectedFile(filename: string): void {
    // check if comment attachment contains infected filename
    if (
      this.clearToCloseData.commentData.commentAttachment.length > 0 &&
      !this.clearToCloseData.commentData.commentAttachment[0].hasOwnProperty("path")
    )
    {
      this.clearToCloseData.commentData.commentAttachment = this.clearToCloseData.commentData.commentAttachment.filter(file => (file.name !== filename));
    }
    // check if creditReportInvoice contains infected filename
    if (
      this.clearToCloseData.creditReportInvoice &&
      !this.clearToCloseData.creditReportInvoice.hasOwnProperty("path")
    ) {
      this.clearToCloseData.creditReportInvoice = this.clearToCloseData.creditReportInvoice.filter(file => (file.name !== filename));
    }
    // check if clearToCloseData contains infected filename
    if (
      this.clearToCloseData.rateLock &&
      !this.clearToCloseData.rateLock.hasOwnProperty("path")
    ) {
      this.clearToCloseData.rateLock = this.clearToCloseData.rateLock.filter(file => (file.name !== filename));
    }
  }


  async uploadFile(event, uploadedFileName) {
    if (event.target.files.length > 0) {
      Object.values(event.target.files).forEach(element => {
          this.clearToCloseData[uploadedFileName].push(element);
      });
    }
  }

  public displayUploadFiles(file, viewFileName) {
    //  this.fileNames =viewFileName
    this.$refs.comment["displayCommentAttachment"](file, true, viewFileName);
  }
  public deleteDoc(obj){
  this.clearToCloseData[obj.deleteData].splice(obj.index,1)
  }
  public async deleteFile(obj) {
    let index = this.clearToCloseData[obj.deleteData].findIndex(
      doc => doc.path == obj.files
    );
  this.clearToCloseData[obj.deleteData].splice(index,1)
    await this.deleteDisplayFromTaskCollection(index, obj.deleteData);
  }
  public async deleteDisplayFromTaskCollection(index, categoryName) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/deleteDocumentByCategoryName",
        { loanTxnId: this.loanTxnId, index: index, categoryName: categoryName });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * To upload wemlo invoice
   */
  public uploadWemloInvoice(event,type) {
    try {
      if (event.target.files.length > 0) {
      this.clearToCloseData.wemloInvoice = event.target.files[0]
    }
    } catch (error) {
      console.log(error);
    }
  }


  /**
   * To upload credit report  invoice
   */
  public uploadCreditReportInvoice(event,type) {
    try {
      if (event.target.files.length > 0) {
      this.clearToCloseData.creditReportInvoice = event.target.files[0]
    }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * To display wemlo invoice files
   */
  public viewWemloInvoice(file,fileName) {
      this.fileNames = fileName
    this.$refs.comment["displayCommentAttachment"](file, true);
  }





  public async deleteInvoice(check,fileName){
    if(fileName == 'wemloInvoice'){
      this.$set(this.clearToCloseData, 'wemloInvoice', 0);
    if(check=='S3')
      await this.deleteInvoiceFromTaskCollection('tasks.clearToClose.wemloInvoice');
    }
    else{
      this.$set(this.clearToCloseData, 'creditReportInvoice', 0);
      if(check=='S3')
        await this.deleteInvoiceFromTaskCollection('tasks.clearToClose.creditReportInvoice');
    }

  }




  async deleteInvoiceFromTaskCollection(path){
    try {
      let response = await Axios.post(BASE_API_URL+ 'wemloprocessor/removeDataFromTaskCollection',
        {loanTxnId:this.loanTxnId , path: path });
    } catch (error) {
      console.log(error);
    }
  }


  async beforeMount() {
      this.loanTxnId = this.$route.query.id;
    await this.fetchClearToCloseTaskDetail();
  }


}

