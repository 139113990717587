


import { Component } from "vue-property-decorator";
import { BASE_API_URL,EventBus } from "@/config";
import Axios from "axios";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import { mixins } from "vue-class-component";


@Component
export default class UwTaskComponent extends mixins(PipeLineSocketMixin) {
  public selectedUwTask: string = null;
  public pipeLineHeaderData: any = null;
  public taskNameForRoom = 'uw';


  private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "UW Response", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/UWResponse.mp4' });
  }
  public async submit() {
    try {
      /**
       * Validating Fields
       */
      let valid = await this.$validator.validateAll();
      if(!valid)  return;

      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/saveSelectedUwTask",
        {
          selectedUwTask: this.selectedUwTask,
          loanTxnId: this.$route.query.id
        });
      this.$snotify[response.data.status == 200 ? "success" : response.data.status == 403 ? "info" : "error"](response.data.message);
      if (response.data.status == 200) {
        this.saveEvent();
      }
      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;
      this.$router.push({ path: "dashboard" });
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public async fetchUwTaskDetails() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchUwTaskDetails",
        {
          loanTxnId: this.$route.query.id
        });

      /**
       * Binding response pipelineHeader in pipelineHeader variable to display pipline header data.
       */
      if (response.data.pipelineHeader)
        this.pipeLineHeaderData = response.data.pipelineHeader;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  mounted() {
    this.fetchUwTaskDetails();
  }
}
