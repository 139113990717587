

import { Component } from "vue-property-decorator";
import { PendingFunding } from "@/models/tasks.model";
import AddComment from "@/views/AddComment.vue";
import Axios from "axios";
import moment from "moment";
import { BASE_API_URL } from "../../../../config";
import Datepicker from "vuejs-datepicker";
import ComplianceChecker from "./ComplianceChecker.vue";
import  QualityAssurance  from "../quality-assurance/QualityAssurance.vue";
import _ from "lodash";
import { LOAN_TERM, ALLOWED_FILE_EXTENSIONS } from "@/constants";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";

@Component({ components: { AddComment, Datepicker, ComplianceChecker, QualityAssurance } })
export default class PendingFundingComponent extends mixins(PipeLineSocketMixin, CommonMixin) {
  public loanTerm = LOAN_TERM;
  public taskNameForRoom = 'pending-finding';
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public pendingFundingData = new PendingFunding();
  public pipeLineHeaderData: any = {};
  public compliancePackageValid: boolean = false;
  public loanInformation: any = { 
    fileRevenue: 0,
    marketingExpenses: 0,
    managerComp: 0,
    loComp: 0,
    processorComp: 0,
    propertyValue: 0,
    loanAmount: 0,
    rate: 0,
    termValue: 0,
    MIP: 0,
  };
  public mortgageAppliedFor: any = {
    ppeValue: "",
    name: "",
    ediValue: "",
    mismoValue: "",
    value: "",
  };

  public complianceData = null;
  public loanTxnId = null;
  public fileNames = null;
  public index = null;
  public currentStep = 1;
  public processorList: any[] = [];
  public fundingTaskLoaded = false;
  public brokerageCheck = { 
    checkDate: null,
    checkNumber: null,
    totalAmount: 0,
  };

  public wemloCheck =  { 
    processorName: null,
    comission: 0,
    paymentAmount: 0,
    isClosingDateCorrect: null,
  };
  
  public brokerageCheckBreakdown = { 
    compensationDollars: 0,
    reimbersedFee: 0,
    totalCure: 0,
  };

  public revenue = {
    fileRevenue: 0,
    marketingExpenses: 0,
    managerComp: 0,
    loComp: 0,
    processorComp: 0,
  };

  get mipValidation() {
    const isVAorFHA = ['VA', 'FHA'].includes(this.mortgageAppliedFor.value);

    if (isVAorFHA) {
      return 'required|min_value:1';
  }

    return '';
  }

  public onChangeClosingDate(closingDate) {
    /**
     * Set the closing date as utc 12:00 hours
     */
    if (closingDate instanceof Date) {
      this.loanInformation.closingDate = moment(closingDate).utc().hours(12).minutes(0).toDate();
    }
  }

  public async submit(ifSave) {
    try {
      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment['validateComment'](ifSave);

      if ((ifSave ? false : !await this.$validator.validateAll() ))
        return;

      // TODO: check the code
      if(!ifSave && this.pendingFundingData.additionalTaskCount>0){
        this.$modal.show('warning');
        return;
      }
      /**
       * Taking Formdata for sending files and data
       */
      this.$store.state.wemloLoader = true;
      let formData = new FormData();


      // Appending loanTxnId here
      formData.append("loanTxnId", this.loanTxnId);
      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.pendingFundingData.commentData.comment,
        showCommentToBroker: this.pendingFundingData.commentData.showCommentToBroker
      }
      if (this.pendingFundingData.commentData.commentAttachment.length > 0 && !this.pendingFundingData.commentData.commentAttachment[0].hasOwnProperty("path"))
        this.pendingFundingData.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.pendingFundingData.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.pendingFundingData.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));

      /**To set uploading in pending funding brokerCheck ,final Note ,finalMorg */
      if (
        this.pendingFundingData.brokerCheck &&
        !this.pendingFundingData.brokerCheck.hasOwnProperty("path")
      )
        this.pendingFundingData.brokerCheck.forEach(file => {
          formData.append("brokerCheck", file);
        });
      if (
        this.pendingFundingData.wemloCheck &&
        !this.pendingFundingData.wemloCheck.hasOwnProperty("path")
      )
        this.pendingFundingData.wemloCheck.forEach(file => {
          formData.append("wemloCheck", file);
        });

      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.pendingFundingData.options));
      let currentStep = this.currentStep;
      if(!ifSave && currentStep != 3) currentStep = this.currentStep+1;
      formData.append("currentStep", String(this.currentStep));
      const loanDetails = {
        MIP: this.loanInformation.MIP,
        closingDate: this.loanInformation.closingDate,
        fundingDate: this.loanInformation.fundingDate,
        statusDate: this.loanInformation.statusDate,
        propertyValue: this.loanInformation.propertyValue,
        loanAmount: this.loanInformation.loanAmount,
        rate: this.loanInformation.rate,
        term: this.loanInformation.term,
        termValue: this.loanInformation.termValue,
      }
      formData.append("complianceData", JSON.stringify({
        loanDetails,
        brokerageCheck: this.brokerageCheck,
        wemloCheck: this.wemloCheck,
        brokerageCheckBreakdown: this.brokerageCheckBreakdown,
        revenue: this.revenue
      }));

      /**
       * Finally sending request to server with formdata in body
       */

      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/pendingFunding",
        formData);
      this.$snotify[response.data.status == 200 ? "success" : response.data.status == 403 ? "info": "error"](response.data.message);

      if (response.data.status == 200) {
        this.saveEvent();
      }

      if(!ifSave && this.currentStep < 3 ){
        this.currentStep = currentStep;
      }else if(this.currentStep == 3){
        this.$router.push({ path: "dashboard" });
      }

      return response.data.status == 200;
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  public removeInfectedFile(filename: string): void {
      // check if comment attachment contains infected filename
      if (
          this.pendingFundingData.commentData.commentAttachment.length > 0 &&
          !this.pendingFundingData.commentData.commentAttachment[0].hasOwnProperty("path")
      )
      {
          this.pendingFundingData.commentData.commentAttachment = this.pendingFundingData.commentData.commentAttachment.filter(file => (file.name !== filename));
      }
      // check if pendingFundingData contains infected filename
      if (
          this.pendingFundingData.brokerCheck &&
          !this.pendingFundingData.brokerCheck.hasOwnProperty("path")
      ) {
          this.pendingFundingData.brokerCheck = this.pendingFundingData.brokerCheck.filter(file => (file.name !== filename));
      }
      // check if wemloCheck contains infected filename
      if (
          this.pendingFundingData.wemloCheck &&
          !this.pendingFundingData.wemloCheck.hasOwnProperty("path")
      ) {
          this.pendingFundingData.wemloCheck = this.pendingFundingData.wemloCheck.filter(file => (file.name !== filename));
      }
  }

  saveCompliancePackageOrder() {
    let $saveCompliance = _.get(this.$refs, 'ComplianceChecker.saveCompliance')
    if($saveCompliance) {
      this.$refs['ComplianceChecker']['saveCompliance']();
      this.saveEvent();
    }
  }

  async fetchComplianceStatus() {
    try {
      const { data } = await Axios.post(BASE_API_URL + "compliance/isCompliancePackageValid", { loanTxnId: this.loanTxnId });

      this.compliancePackageValid = data;
    } catch (error) {
      console.log(error);
    }
  }

  get expectedCheckTotal(){
    const {compensationDollars, reimbersedFee, totalCure} = this.brokerageCheckBreakdown;
    return compensationDollars + (reimbersedFee || 0) - (totalCure || 0)
  }


  get netFinalRevenueTotal(){
    const { fileRevenue, marketingExpenses, managerComp, loComp, processorComp} = this.revenue;
    return fileRevenue - marketingExpenses - managerComp - loComp - processorComp;
  }


  public async saveInfo(ifSave){
    let data = await this.submit(ifSave);
    if(ifSave == "Archive"){

    }else if(data &&  ifSave === "Finish Loan"){
      this.currentStep = 3;
    } else if(data && ifSave != "Save"){
      this.currentStep = 2;
    } 
  }

  async uploadFile(event, uploadedFileName) {
    if (event.target.files.length > 0) {
      Object.values(event.target.files).forEach(element => {
        if (uploadedFileName == "brokerCheck") {
          this.pendingFundingData.brokerCheck.push(element);
        } else if (uploadedFileName == "wemloCheck") {
          this.pendingFundingData.wemloCheck.push(element);
        }
      });
    }
  }
  public displayUploadFiles(file, viewFileName) {
    this.fileNames = viewFileName
    this.$refs.comment["displayCommentAttachment"](file, true, viewFileName);
  }
  public deleteDoc(obj){
    this.pendingFundingData[this.fileNames].splice(obj.index,1)
  }
  public async deleteFile(obj) {
    let index = this.pendingFundingData[obj.deleteData].findIndex(
      doc => doc.path == obj.files
    );
  this.pendingFundingData[obj.deleteData].splice(index,1)
    await this.deleteDisplayFromTaskCollection(index, obj.deleteData);
  }
  public async deleteDisplayFromTaskCollection(index, categoryName) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/deleteDocumentByCategoryName",
        { loanTxnId: this.loanTxnId, index: index, categoryName: categoryName });
    } catch (error) {
      console.log(error);
    }
  }

  public getShortname(compensationType) {
        if (!compensationType) return '';
        let list = compensationType.split(' ');
        list = list.map(i => i[0]);
        let name = list.join('');
        if (name) {
            return `${name}`
        }
    }

  public async fetchPendingFundingTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/fetchPendingFundingTaskDetail",
        {
          params: {
            loanTxnId: this.$route.query.id
          }
        }
      );
      // Redirect user to pending funding version 1 if he is working on loans older than V3.
      if (response.data.loanInformation.loanVersion < 'V3') {// Also handles, if someone hits the URL directly.
        this.fundingTaskLoaded = true;
        this.$router.replace({ name: "Task Funding V1", query: {id: this.$route.query.id}});
      }
      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.pendingFundingData = response.data.pendingFundingData;
      this.loanInformation = Object.assign(this.loanInformation, response.data.loanInformation);
      this.mortgageAppliedFor = Object.assign(this.mortgageAppliedFor, response.data.mortgageAppliedFor);
      this.$store.state.wemloLoader = false;
      this.loanInformation.compensationType = this.getShortname(this.loanInformation.compensationType);
      this.brokerageCheckBreakdown.compensationDollars = this.loanInformation.compensationDollars;
      this.complianceData = this.pendingFundingData.complianceData;

      /**
       * Set processor of record on wemlo check
       */
      this.wemloCheck.processorName = response.data.processorOfRecord;

      if(this.complianceData){
        this.revenue = this.complianceData.revenue;
        this.brokerageCheck = this.complianceData.brokerageCheck;
        this.wemloCheck = this.complianceData.wemloCheck;
        this.brokerageCheckBreakdown = this.complianceData.brokerageCheckBreakdown;
        this.loanInformation.closingDate = this.complianceData.loanDetails.closingDate;
        this.loanInformation.fundingDate = this.complianceData.loanDetails.fundingDate;
        this.loanInformation.statusDate = this.complianceData.loanDetails.statusDate;
        this.loanInformation.termValue = this.complianceData.loanDetails.termValue;
      }else{
        /**
         * Term is null using skip los validation and submission
         */
        this.loanInformation.termValue = _.get(this.loanInformation, 'term.value');
        this.loanInformation.closingDate = this.pipeLineHeaderData.updatedClosingDate;
      }
      this.currentStep = this.pendingFundingData.currentStep || 1;
      this.fundingTaskLoaded = true;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  async fetchProcessorList(){
    let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getAllWemloProcessorList",
        {
          params: { userId: this.$userId }
        }
      );
      this.processorList = response.data.wemloStaffList;
  }

  public onChangeTerm(term) {
    if (term) {
      this.loanInformation.termValue = term.value;
    }
  }

  public continueWithoutAdditionalTasks() {
      this.pendingFundingData.additionalTaskCount = 0;
      this.$modal.hide('warning');
      this.submit('Complete');
  }

  async beforeMount() {
    this.loanTxnId = this.$route.query.id;
    await this.fetchPendingFundingTaskDetail();
    await this.fetchProcessorList();
    await this.fetchComplianceStatus();
  }


}
