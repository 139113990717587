


import { Component } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL,EventBus } from "@/config";

import AddConditions from "@/views/AddConditions.vue";
import { Suspended } from "@/models/tasks.model";
import Datepicker from "vuejs-datepicker";
import AddComment from "@/views/AddComment.vue";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";

@Component({ components: { AddConditions, Datepicker, AddComment } })
export default class SuspendedComponent extends mixins(PipeLineSocketMixin, CommonMixin) {
  public loanTxnId: any = null;
  public pipeLineHeaderData: string = null;
  public suspendedData: Suspended = new Suspended();
  public taskNameForRoom = 'suspended';

   private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Suspended", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/Suspended.mp4' });
  }
  public async fetchSuspendedTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchSuspendedDetails",
        {
          loanTxnId: this.loanTxnId
        });
      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.suspendedData = response.data.suspendedData;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public async submit(ifSave) {
    try {
      /**
       * Validating conditions here
       */
      ifSave = ifSave == "Save" ? true : false;
      let validConditions = await this.$refs.conditions["validate"](ifSave);
      let validComment = await this.$refs.comment['validateComment'](ifSave);
      /**
       * If clicked on Complete then validate all fields otherwise
       * check conditions if not validate either both then return;
       */
      if ((ifSave ? false : !await this.$validator.validateAll() ) || !validConditions || !validComment)
        return;

      /**
       * Taking Formdata for sending files and data
       */
      this.$store.state.wemloLoader = true;
      let formData = new FormData();
      /**
       * Merging Conditions here
       */
      this.suspendedData.borrowerCondition.forEach((f, i) => {
        if (f.samples.length > 0 && !f.samples[0].hasOwnProperty("path")) {
          f.samples.forEach(file => formData.append("borrowerCondition" + i, file));
        }
      });
      this.suspendedData.thirdPartyCondition.forEach((f, i) => {
        if (f.samples.length > 0 && !f.samples[0].hasOwnProperty("path")) {
          f.samples.forEach(file => formData.append("thirdPartyCondition" + i, file));
        }
      });
      formData.append(
        "borrowerCondition",
        JSON.stringify(
          this.suspendedData.borrowerCondition.map(b => {
            const { fileHistory, ...withoutHistory } = b;
            return { ...withoutHistory, samples: []};
          })
        )
      );
      formData.append(
        "thirdPartyCondition",
        JSON.stringify(
          this.suspendedData.thirdPartyCondition.map(t => {
            const { fileHistory, ...withoutHistory } = t;
            return { ...withoutHistory, samples: []};
          })
        )
      );

      //  Appending LoanTxnId in formData
      formData.append("loanTxnId", this.loanTxnId);
      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.suspendedData.commentData.comment,
        showCommentToBroker: this.suspendedData.commentData.showCommentToBroker
      }
      if (this.suspendedData.commentData.commentAttachment.length > 0 && !this.suspendedData.commentData.commentAttachment[0].hasOwnProperty("path"))
        this.suspendedData.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.suspendedData.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.suspendedData.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));
      /**
       * Merging the save or complete option here
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.suspendedData.options));


      /**
       * Sending request to server with formdata in body.
       */
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/saveSuspendedData",
        formData);

      this.$snotify[response.data.status == 200 ? "success" : response.data.status == 403 ? "info" : "error"](response.data.message);
      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      if (response.data.status == 200) {
        this.saveEvent();
      }

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  removeInfectedFile(filename: string): void {
      // check if borrower conditions contain infected filename
      this.suspendedData.borrowerCondition.forEach((f,i) => {
          this.filterSamplesIfInfected(f, filename);
      });

      // check if 3rd party conditions contain infected filename
      this.suspendedData.thirdPartyCondition.forEach((f, i) => {
          this.filterSamplesIfInfected(f, filename);
      });

      // check if comment attachment contains infected filename
      if (
          this.suspendedData.commentData.commentAttachment.length > 0 &&
          !this.suspendedData.commentData.commentAttachment[0].hasOwnProperty(
              "path"
          )
      )
      {
          this.suspendedData.commentData.commentAttachment = this.suspendedData.commentData.commentAttachment.filter(file => (file.name !== filename));
      }
  }

  refreshDocumentStatusById(docId){
    let borrowerOrThirdPartyCondition = this.suspendedData.borrowerCondition.find(d=>d.documentId==docId)?'borrowerCondition':'thirdPartyCondition'
    let index = this.suspendedData[borrowerOrThirdPartyCondition].findIndex(d=>d.documentId==docId);
    this.suspendedData[borrowerOrThirdPartyCondition][index].hasApprovedToSubmit = true;
  }

  async beforeMount() {
    this.loanTxnId = this.$route.query.id;
    await this.fetchSuspendedTaskDetail();
  }


}
