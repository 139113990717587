


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL, PHONE_EXT } from "@/config";
import Axios from "axios";

import { HollowDotsSpinner } from "epic-spinners";
import { mixins } from "vue-class-component";
import Datepicker from "vuejs-datepicker";
import CommonMixin from "../../../../mixins/common.mixin";
import CommentTask from "@/views/Comments.vue";
import stores from "@/store/store";
import DisplayFiles from "@/views/DisplayDocument.vue";
import moment from "moment";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";

const store: any = stores;
@Component({
  components: { HollowDotsSpinner, Datepicker, CommentTask, DisplayFiles }
})
export default class UWTask extends mixins(CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public borrowerInfo: any = {};
  public hasContactedCompany: any = false;
  public hasContactedBorrower: any = false;
  public hasClosingInitiated: any = false;
  public hasContactedMlo: any = false;
  public isExtended: any = true;
  public isClosingDate: any = null;
  public extendedDate: any = null;
  public status = "";
  public save = true;
  public uwTaskSelect: any = null;
  public show = false;
  public files = [];
  // public clearToCloseCommentFiles = []
  public commentFiles = [];
  public showDropDown = true;
  public fileType = "";
  public fileToDisplay = null;
  public viewFileHide = false;
  public indexApproved = 0;
  public indexFrom = "";
  public denial = {
    hasContactedBorrower: false,
    hasContactedMlo: false,
    hasUploadDenial: false
  };

  public denialShow = false;
  public hasUploadDenial: any = false;
  public errorMessage = {
    extendedDate: false,
    hasContactedCompany: false,
    hasContactedBorrower: false,
    hasClosingInitiated: false,
    hasContactedMlo: false,
    isClosingDate: false,
    files: false,
    UploadDenial: false,
    commentFiles: false,
    updatedRateExpirationDate: false,
    ctcHasFloatRate: false,
    ctcRateLockDate: false,
    caRateLockDate: false
  };
  //-UW Response Conditional Approval
  public dotLoader = false;
  public fileIndex = 0;
  public fileArray = [];
  public showPrev = false;
  public showNext = false;
  public modalIndex = 0;
  public modalIndex1 = 0;
  public commentIndex = 0;
  public commentIndex1 = 0;
  public commentAttachs = 0;
  public fileUploadedFrom = [];
  public firstBorrowerComment = true;
  public firstThirdPartyComment = true;
  public suspendedFirstBorrowerComment = true;
  public suspendedFirstThirdPartyComment = true;
  public borrowerCondition = [];
  public thirdPartyCondition = [];

  //==========================================================
  //Using for suspended Task now
  public suspendedBorrowerCondition = [];
  public suspendedThirdPartyCondition = [];
  public suspendedCheckBox = {
    hasContactedBorrower: false,
    hasConnectedMLO: false,
    uploadSuspenseLetter: false
  };
  public addSuspended = false;
  public removeConditionArray = [];
  //==========================================================
  public hasCAContactedBorrower = false;
  public hasDisclosuresInitiated = false;
  public submittedToUnderwriter = false;
  public commentBorrowerCondition = [];
  public commentThirdPartyCondition = [];
  public caRateLockDate: any = null;
  public caHasFloatRate: string = null;
  public ctcRateLockDate: any = null;
  public ctcHasFloatRate: string = null;
  public borrowerConditionComments = false;
  public thirdPartyConditionComments = false;
  public onConditionalApproval = false;
  public onSuspended = false;
  public pipeLineHeaderData: any = {};
  // public save = false;
  public hasConnectedMLO = false;
  public uploadSuspenseLetter = false;
  public brokerName = null;
  public documents: any = {};
  public comment = "";
  public review = false;
  public reviewDoc: any = {};
  public DatePickerFormat = "dd/MM/yyyy";
  public stampedApprovalDate = "";
  public state: any = {};
  public dateSame = false;
  public commentSampleAttachment = [];
  public reClassify = null;
  public reClassifyArray = [];
  public countFile = "";
  public firstCount = false;
  public errorComment = false;
  public completed = false;
  public inProcessSave = false;
  public inProcessComplete = false;
  public countFiles = 0;
  public change = false;
  public addFiles = [];
  public newFilesCount = 0;
  public errorFile = false;
  public allFileTypes = [];

  // -----Instance Variables for Comment --
  public isActiveCommentClearToClose = false;
  public isActiveCommentConditional = false;
  public isActiveCommentSuspended = false;
  public commentConditionalApproval = null;
  public commentChange = false;
  public fileChange = false;
  public commentSuspended = null;
  public fileConditional = [];
  public fileSuspended = [];
  public countFileConditional = 0;
  public countFileSuspended = 0;
  public brokerId = null;
  public addedBy = null;
  public countFileDenial = 0;
  public fileDenial = [];
  public isActiveCommentDenial = false;
  public commentDenial = null;
  // -----------------------------------
  public fileChangeCTC = false;
  public deleteSamples = false;
  public samplesIndex = null;
  public samplesType = "";
  public deleteInvoiceFiles = false;
  public removeConditionCAArray = [];
  public borrowerName = null;
  public loanTxnId = null;
  public sendSmsToBroker = null;
  public sendSmsToMlo = null;
  public isWemloInvoiceCtc = false;
  public updatedClosingDate = null;
  public closingDateConditional = null;
  public originatorModelName = null;
  public updatedRateExpirationDate = null;
  public caDateError = false;
  public ctcDateError = false;
  //**********************************************
  public loanAudit = {
    loanTxnId: null,
    source: null,
    updateOn: new Date(),
    updatedBy: this.$userId,
    activity: {
      closingDate: null,
      rateType: null,
      rateLockExpirationDate: null,
      rateLockDate: null,
      extendedDate: null
    }
  };
  //**********************************************
  public async fetchTaskDetails() {
    let response = await Axios.post(
      BASE_API_URL + "wemloprocessor/taskDetails",
      { loanTxnId: this.$route.query.id });
    this.caHasFloatRate = response.data.updatedRateType;
    this.ctcHasFloatRate = response.data.updatedRateType;
    if (response.data.updatedRateLockExtensionDate != null) {
      this.isExtended = true;
      this.extendedDate = response.data.updatedRateLockExtensionDate;
    } else {
      this.isExtended = false;
      this.extendedDate = null;
    }
    if (response.data.tasks.tasks.uwTask.conditionalApproval.length > 0) {
      this.hasClosingInitiated =
        response.data.tasks.tasks.uwTask.conditionalApproval[
          response.data.tasks.tasks.uwTask.conditionalApproval.length - 1
        ].hasDisclosuresInitiated;
    }
    if (response.data.brokerId != response.data.addedBy) {
      this.mloId = response.data.addedBy;
      this.sendSmsToMlo = response.data.notificationSettingForMlo
        ? response.data.notificationSettingForMlo.sendSms
        : null;
    }
    this.updatedClosingDate = response.data.updatedClosingDate;
    this.originatorModelName = response.data.originatorModelName;

    this.sendSmsToBroker = response.data.notificationSettingForBroker
      ? response.data.notificationSettingForBroker.sendSms
      : null;
    this.borrowerName =
      response.data.basicInfo.borrowerInfo[0].personalInfo.name.firstName +
      " " +
      response.data.basicInfo.borrowerInfo[0].personalInfo.name.lastName;
    this.updatedRateExpirationDate = response.data.updatedRateExpirationDate;
    this.brokerPhone = response.data.brokerPhone;
    this.mloPhone = response.data.mloPhone;
    this.loanTxnId = response.data.loanTxnId;
    this.brokerId = response.data.brokerId;
    this.addedBy = response.data.addedBy;
    this.suspendedBorrowerCondition = [];
    this.suspendedThirdPartyCondition = [];
    this.brokerName = response.data.brokerName;
    this.pipeLineHeaderData = this.assignPipelineHeaderData(
      response.data.pipelineData,
      false
    );
    if (response.data.updatedRateLockDate) {
      this.ctcRateLockDate = response.data.updatedRateLockDate;
      this.caRateLockDate = response.data.updatedRateLockDate;
    }

    if (this.$route.query.condition) {
      this.uwTaskSelect = this.$route.query.condition;
      this.show = false;
      this.denialShow = false;
      this.onConditionalApproval = true;
      this.status = this.uwTaskSelect;
      this.showDropDown = false;
      this.firstCount = true;
    }
    if (
      response.data.loanStatus[0].name == "Conditional Approval" ||
      response.data.loanStatus[0].name == "Suspended" ||
      response.data.loanStatus[0].name == "Clear To Close" ||
      response.data.loanStatus[0].name == "Denial"
    ) {
      this.firstCount = true;
    }

    this.borrowerInfo = response.data;
    this.documents = response.data.documents;
    this.setReClassifyValues(this.documents);
    this.isClosingDate = response.data.updatedClosingDate;
    this.closingDateConditional = response.data.updatedClosingDate;
    //Setting Up incoming information to show
    if (response.data.loanStatus[0].name != "Condition Submitted") {
      let ctcTaskData = response.data.tasks.tasks.uwTask.clearToClose[0];
      // let conditionalTaskData =
      //   response.data.tasks.tasks.uwTask.conditionalApproval;
      let conditionalTaskData =
        response.data.tasks.tasks.uwTask.conditionalApproval.length > 0 &&
        response.data.tasks.tasks.uwTask.conditionalApproval[
          response.data.tasks.tasks.uwTask.conditionalApproval.length - 1
        ];
      let taskDataSuspended = response.data.tasks.tasks.uwTask.suspended;
      let taskDataDenial = response.data.tasks.tasks.uwTask.denial;
      if (conditionalTaskData) {
        this.hasCAContactedBorrower = conditionalTaskData.hasContactedBorrower;
        this.hasDisclosuresInitiated =
          conditionalTaskData.hasDisclosuresInitiated;
        this.submittedToUnderwriter =
          conditionalTaskData.submittedToUnderwriter;
        this.isActiveCommentConditional = conditionalTaskData.isActiveComment;
        this.stampedApprovalDate = conditionalTaskData.stampedApprovalDate;
        if (conditionalTaskData.borrowerCondition.length > 0) {
          this.borrowerCondition = [];
          conditionalTaskData.borrowerCondition.forEach(d => {
            if (d.commentIndex != null) {
              this.firstBorrowerComment = false;
            }
            let a = this.documents.filter(b => {
              if (b._id == d.documentId) return b;
            });
            if (a.length > 0) {
              if (
                a[0].fileHistory.length > 0 &&
                a[0].fileHistory[a[0].fileHistory.length - 1].status ==
                  "Approved"
              ) {
                d.hasApprovedToSubmit = true;
              }

              // add conditional samples with borrowerCondition
              d.samples = a[0].samples;
            }

            if (d.samples && d.samples.length > 0) {
              d.samplesCount = d.samples.length;
            } else {
              d.samplesCount = null;
            }
            // end
            this.borrowerCondition.push(d);
          });
        }
        if (conditionalTaskData.thirdPartyCondition.length > 0) {
          this.thirdPartyCondition = [];

          conditionalTaskData.thirdPartyCondition.forEach(d => {
            let a = this.documents.filter(b => {
              if (b._id == d.documentId) return b;
            });
            if (a.length > 0) {
              if (
                a[0].fileHistory.length > 0 &&
                a[0].fileHistory[a[0].fileHistory.length - 1].status ==
                  "Approved"
              ) {
                d.hasApprovedToSubmit = true;
              }
              // add conditional samples with thirdPartCondition
              d.samples = a[0].samples;
            }
            if (d.commentIndex != null) {
              let obj = {
                documents: null
              };
              this.firstThirdPartyComment = false;
            }

            if (d.samples && d.samples.length > 0) {
              d.samplesCount = d.samples.length;
            } else {
              d.samplesCount = null;
            }
            // end

            // if thirdPartyCondition catagoryType  'Other'
            if (
              d.companyTitle &&
              d.companyTitle != "Title" &&
              d.companyTitle != "Appraisal" &&
              d.companyTitle != "Real Estate"
            ) {
              d.otherCompanyTitle = d.companyTitle;
              d.companyTitle = "Other";
            }
            this.thirdPartyCondition.push(d);
          });
        }
      }

      // conditionalTaskData.forEach(element => {
      //   this.hasCAContactedBorrower = element.hasContactedBorrower;
      //   this.hasDisclosuresInitiated = element.hasDisclosuresInitiated;
      //   this.submittedToUnderwriter = element.submittedToUnderwriter;
      //   this.isActiveCommentConditional = element.isActiveComment;
      //   this.stampedApprovalDate = element.stampedApprovalDate;
      //   //this.closingDateConditional = element.closingDate;
      //   let loopLength = element.borrowerCondition.length;
      //   if (loopLength > 0) this.borrowerCondition = [];
      //   element.borrowerCondition.forEach(d => {
      //     if (d.commentIndex != null) {
      //       this.firstBorrowerComment = false;
      //     }
      //     let a = this.documents.filter(b => {
      //       if (b._id == d.documentId) return b;
      //     });
      //     if (a.length > 0) {
      //       if (
      //         a[0].fileHistory.length > 0 &&
      //         a[0].fileHistory[a[0].fileHistory.length - 1].status == "Approved"
      //       ) {
      //         d.hasApprovedToSubmit = true;
      //       }
      //     }

      //     // add conditional samples with borrowerCondition
      //     d.samples = a[0].samples;
      //     if (d.samples.length > 0) {
      //       d.samplesCount = d.samples.length;
      //     } else {
      //       d.samplesCount = null;
      //     }
      //     // end
      //     this.borrowerCondition.push(d);
      //   });
      //   let loopLength1 = element.thirdPartyCondition.length;

      //   if (loopLength1 > 0) this.thirdPartyCondition = [];
      //   element.thirdPartyCondition.forEach(d => {
      //     let a = this.documents.filter(b => {
      //       if (b._id == d.documentId) return b;
      //     });
      //     if (a.length > 0) {
      //       if (
      //         a[0].fileHistory.length > 0 &&
      //         a[0].fileHistory[a[0].fileHistory.length - 1].status == "Approved"
      //       ) {
      //         d.hasApprovedToSubmit = true;
      //       }
      //     }
      //     if (d.commentIndex != null) {
      //       let obj = {
      //         documents: null
      //       };
      //       this.firstThirdPartyComment = false;
      //     }

      //     // add conditional samples with thirdPartCondition
      //     d.samples = a[0].samples;
      //     if (d.samples.length > 0) {
      //       d.samplesCount = d.samples.length;
      //     } else {
      //       d.samplesCount = null;
      //     }
      //     // end

      //     // if thirdPartyCondition catagoryType  'Other'
      //     if (
      //       d.companyTitle != "Title" &&
      //       d.companyTitle != "Appraisal" &&
      //       d.companyTitle != "Real Estate"
      //     ) {
      //       d.otherCompanyTitle = d.companyTitle;
      //       d.companyTitle = "Other";
      //     }
      //     this.thirdPartyCondition.push(d);
      //   });
      // });
      if (response.data.comments) {
        if (conditionalTaskData && conditionalTaskData.commentIndex) {
          let commentDataConditional =
            response.data.comments.comments[conditionalTaskData.commentIndex];
          this.commentConditionalApproval = commentDataConditional.comment;
          if (commentDataConditional.attachments.length > 0) {
            this.countFileConditional =
              commentDataConditional.attachments.length;
            this.fileConditional = commentDataConditional.attachments;
          }
        }
        if (
          taskDataSuspended.length > 0 &&
          taskDataSuspended[taskDataSuspended.length - 1].commentIndex
        ) {
          let commentIndexSuspended =
            taskDataSuspended[taskDataSuspended.length - 1].commentIndex;
          let commentDataSuspeded =
            response.data.comments.comments[commentIndexSuspended];
          this.commentSuspended = commentDataSuspeded.comment;
          if (commentDataSuspeded.attachments.length > 0) {
            this.countFileSuspended = commentDataSuspeded.attachments.length;
            this.fileSuspended = commentDataSuspeded.attachments;
          }
        }
        if (
          taskDataDenial.length > 0 &&
          taskDataDenial[taskDataDenial.length - 1].commentIndex
        ) {
          let commentDataDenial =
            response.data.comments.comments[
              taskDataDenial[taskDataDenial.length - 1].commentIndex
            ];
          this.commentDenial = commentDataDenial.comment;
          if (commentDataDenial.attachments.length > 0) {
            this.fileDenial = commentDataDenial.attachments;
            this.countFileDenial = commentDataDenial.attachments.length;
          }
        }
      }
      if (
        response.data.comments &&
        ctcTaskData &&
        ctcTaskData.commentIndex != null
      ) {
        let commentData =
          response.data.comments.comments[ctcTaskData.commentIndex];
        this.comment = commentData.comment;
        if (commentData.attachments.length > 0) {
          this.commentFiles = commentData.attachments;
          this.countFiles = commentData.attachments.length;
          this.countFiles = JSON.parse(JSON.stringify(this.countFiles));
        }
      }
    }

    //Setting Up incoming information to show suspended
    if (response.data.loanStatus[0].name == "Suspended") {
      //coming when comes after saving
      let taskDataSuspended = this.borrowerInfo.tasks.tasks.uwTask.suspended;
      taskDataSuspended = taskDataSuspended[taskDataSuspended.length - 1];
      if (taskDataSuspended.hasContactedBorrower)
        this.suspendedCheckBox.hasContactedBorrower =
          taskDataSuspended.hasContactedBorrower;
      if (taskDataSuspended.hasConnectedMLO)
        this.suspendedCheckBox.hasConnectedMLO =
          taskDataSuspended.hasConnectedMLO;
      if (taskDataSuspended.uploadSuspenseLetter)
        this.suspendedCheckBox.uploadSuspenseLetter =
          taskDataSuspended.uploadSuspenseLetter;
      if (taskDataSuspended.isActiveComment) {
        this.isActiveCommentSuspended = taskDataSuspended.isActiveComment;
      }
      if (taskDataSuspended.borrowerCondition.length == 0) {
        this.addSuspendedBorrowerCondition();
      }
      taskDataSuspended.borrowerCondition.forEach((t, i) => {
        let doc = this.documents.find(doc => doc._id == t.documentId);
        let obj = {
          conditionName: doc.categoryName,
          documentId: doc._id,
          samples: doc.samples,
          samplesCount: doc.samples.length > 0 ? doc.samples.length : null,
          hasApprovedToSubmit: false
        };

        if (
          doc.fileHistory.length > 0 &&
          doc.fileHistory[doc.fileHistory.length - 1].status == "Approved"
        ) {
          obj.hasApprovedToSubmit = true;
        }
        this.suspendedBorrowerCondition.push(obj);
      });
      if (taskDataSuspended.thirdPartyCondition.length == 0) {
        this.addSuspendedThirdPartyCondition();
      }
      taskDataSuspended.thirdPartyCondition.forEach(t => {
        let doc = this.documents.find(doc => doc._id == t.documentId);
        let obj = {
          conditionName: doc.categoryName,
          otherConditionType: "",
          conditionType: "",
          documentId: doc._id,
          samples: doc.samples,
          samplesCount: doc.samples.length > 0 ? doc.samples.length : null,
          hasApprovedToSubmit: false
        };
        if (
          doc.categoryType &&
          doc.categoryType != "Title" &&
          doc.categoryType != "Appraisal" &&
          doc.categoryType != "Real Estate"
        ) {
          obj.otherConditionType = doc.categoryType;
          obj.conditionType = "Other";
        } else {
          obj.conditionType = doc.categoryType;
        }
        if (
          doc.fileHistory.length > 0 &&
          doc.fileHistory[doc.fileHistory.length - 1].status == "Approved"
        ) {
          obj.hasApprovedToSubmit = true;
        }
        this.suspendedThirdPartyCondition.push(obj);
      });
    }
    //When first Time user comes on suspended
    if (
      this.borrowerInfo.tasks.tasks.uwTask.suspended.length == 0 ||
      response.data.loanStatus[0].name == "Condition Submitted"
    ) {
      this.addSuspended = true;
      this.addSuspendedBorrowerCondition();
      this.addSuspendedThirdPartyCondition();
    }
  }
  public async setReClassifyValues(allDocs) {
    let requiredDocuments = [];
    allDocs.forEach((data, index) => {
      if (data.fileHistory.length == 0) {
        requiredDocuments.push(data);
      } else {
        if (
          data.fileHistory[data.fileHistory.length - 1].status != "Approved"
        ) {
          requiredDocuments.push(data);
        }
      }
    });
    this.reClassifyArray = [];
    requiredDocuments.forEach(f => {
      let obj = {
        categoryName: f.categoryName,
        categoryType: f.categoryType
      };
      this.reClassifyArray.push(obj);
    });
  }
  public addBorrowerCondition() {
    let obj = {
      conditionName: null,
      hasApprovedToSubmit: false,
      commentIndex: null,
      attachments: [],
      samples: [],
      samplesCount: null
    };
    this.borrowerCondition.push(obj);
  }

  public addCommentThirdPartyCondition() {
    let obj = {
      documentName: "",
      comment: "",
      attachments: []
    };
    this.commentThirdPartyCondition.push(obj);
  }

  public addThirdPartyCondition() {
    let obj = {
      companyTitle: null,
      conditionName: null,
      hasApprovedToSubmit: null,
      commentIndex: null,
      otherCompanyTitle: null,
      attachments: [],
      samples: [],
      samplesCount: null
    };
    this.thirdPartyCondition.push(obj);
  }

  public downloadFile(file) {
    window.open(file);
  }

  /************************************************************************************************
   *                                             Functions                                         *
   *                                             For                                               *
   *                                             UW Tasks                                          *
   ************************************************************************************************/

  //Function for adding suspended borrower condition
  public addSuspendedBorrowerCondition() {
    let obj = {
      conditionName: "",
      documentId: null,
      samples: [],
      samplesCount: null,
      hasApprovedToSubmit: false
    };
    this.suspendedBorrowerCondition.push(obj);
  }
  //Function for adding suspended borrower condition ends
  //===============================================================================================

  //Function for adding suspended third party condition
  public addSuspendedThirdPartyCondition() {
    let obj = {
      conditionName: "",
      conditionType: "",
      otherConditionType: "",
      samples: [],
      samplesCount: null,
      documentId: null
    };
    this.suspendedThirdPartyCondition.push(obj);
  }
  //Function for adding suspended third party condition ends
  //===============================================================================================

  //Function On save or complete on suspended Task
  public async submitAndCompletedForSuspended() {
    try {
      if (this.completed) {
        this.inProcessComplete = true;
        let formValid = await this.$validator.validateAll("suspended");
        if (!formValid) {
          this.errorComment = true;
          this.inProcessComplete = false;
          return;
        }
        let checkError = false;
        let checkError2 = false;
        this.suspendedBorrowerCondition.forEach(d => {
          if (d.conditionName != "" && d.conditionName != null) {
            if (!d.hasApprovedToSubmit) {
              checkError = true;
            }
          }
        });
        this.suspendedThirdPartyCondition.forEach(d => {
          if (d.conditionName != "" && d.conditionName != null) {
            if (!d.hasApprovedToSubmit) {
              checkError2 = true;
            }
          }
        });
        if (checkError || checkError2) {
          this.inProcessComplete = false;
          this.$snotify.clear();
          this.$snotify.error("To complete, All conditions should be approved");
          return;
        }
      } else {
        this.inProcessSave = true;
      }
      this.errorComment = false;
      let suspendedThirdPartyCondition = JSON.parse(
        JSON.stringify(this.suspendedThirdPartyCondition)
      );
      for (let i = 0; i < suspendedThirdPartyCondition.length; i++) {
        if (suspendedThirdPartyCondition[i].conditionType == "Other") {
          suspendedThirdPartyCondition[i].conditionType =
            suspendedThirdPartyCondition[i].otherConditionType;
        }
        delete suspendedThirdPartyCondition[i].otherConditionType;
      }
      let formData = new FormData();
      let toUsers = [
        {
          type: this.originatorModelName,
          userId: this.addedBy
        }
      ];
      let data: any = {
        loanTxnId: this.$route.query.id,
        check: this.completed,
        borrowerCondition: this.suspendedBorrowerCondition,
        thirdPartyCondition: suspendedThirdPartyCondition,
        suspendedCheckBox: this.suspendedCheckBox,
        add: this.addSuspended,
        isActiveComment: this.isActiveCommentSuspended,
        commentSuspended: this.commentSuspended,
        fileChange: this.fileChange,
        commentChange: this.commentChange,
        fromUserId: this.$userId,
        fromType: this.$userType,
        toUsers: JSON.stringify(toUsers)
      };
      if (this.removeConditionArray.length > 0) {
        data.removeCondition = this.removeConditionArray;
      }

      Object.entries(this.fileSuspended).forEach((f: any) => {
        formData.append("file", f[1]);
      });
      formData.append("data", JSON.stringify(data));
      this.$store.dispatch("emptyLocalFiles");
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/UWResponseSuspended",
        formData);
      this.inProcessSave = false;
      this.inProcessComplete = false;
      if (response.data.text == "Completed") {
        this.$snotify.info("Task has been already closed", { timeout: 999999 });
        this.$router.push({
          path: "dashboard"
        });
      } else {
        this.$snotify.success(response.data.message);
        this.$router.push({
          path: "dashboard"
        });
      }
    } catch (error) {
      this.inProcessSave = false;
      this.inProcessComplete = false;
      console.log(error);
    }
  }
  //Function on save or Complete on suspended task ends
  //===============================================================================================

  //Function for removing condition
  public async removeCondition(index, from, data) {
    try {
      this[from].splice(index, 1);
      if (data.documentId != null) {
        this.removeConditionCAArray.push({ id: data.documentId });
        this.removeConditionArray.push({ id: data.documentId });
      }
    } catch (error) {
      console.log(error);
    }
  }
  //Function for Removing conddition ends
  //===============================================================================================

  /************************************************************************************************
   *                                          Functions                                            *
   *                                          For                                                  *
   *                                          UW Tasks Ends                                        *
   ************************************************************************************************/

  public async removeBorrowerCondition(index) {
    this.commentIndex = 0;
    let comment = this.borrowerCondition[index].commentIndex;
    this.borrowerCondition.splice(index, 1);
    if (comment != null) {
      this.commentBorrowerCondition.splice(comment, 1);
      for (let i = index; i < this.borrowerCondition.length; i++) {
        if (this.borrowerCondition[i].commentIndex != null) {
          this.borrowerCondition[i].commentIndex--;
        }
      }
    }
  }

  public async removeThirdPartyCondition(index) {
    this.commentIndex1 = 0;
    let comment = this.thirdPartyCondition[index].commentIndex;
    this.thirdPartyCondition.splice(index, 1);
    if (comment != null) {
      this.commentThirdPartyCondition.splice(comment, 1);
      for (let i = index; i < this.thirdPartyCondition.length; i++) {
        if (this.thirdPartyCondition[i].commentIndex != null) {
          this.borrowerCondition[i].commentIndex--;
        }
      }
    }
    if (this.commentThirdPartyCondition.length == 0) {
      this.addCommentThirdPartyCondition();
    }
  }

  public checkDocumentHasFiles(id, check) {
    let data = false;
    if (this.documents.length > 0 && id != null) {
      this.documents.forEach(doc => {
        if (doc._id == id) {
          if (check) {
            if (doc.fileHistory.length > 0) {
              data = true;
            }
          } else {
            if (
              doc.fileHistory.length > 0 &&
              doc.fileHistory[doc.fileHistory.length - 1].status == "Uploaded"
            ) {
              data = true;
            }
          }
        }
      });
    }
    return data;
  }

  /**************************************************************************************************
   *                              DELETING FILE FROM DISPLAY COMPONENT                               *
   **************************************************************************************************/
  public deleteLocalCTC(from) {
    try {
      this[from.deleteData].splice(from.index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  public deleteFile(file) {
    try {
      let delFile = file.files;
      let del = file.deleteData;
      let fromDelete = JSON.parse(JSON.stringify(this[del.main][del.index]));
      if (fromDelete.samplesCount > 1) {
        fromDelete.samplesCount--;
      } else {
        delete fromDelete.samplesCount;
      }
      fromDelete.samples = fromDelete.samples.filter(
        s => s.path != delFile
      );
      this.$set(
        this[del.main],
        del.index,
        JSON.parse(JSON.stringify(fromDelete))
      );
    } catch (error) {
      console.log(error);
    }
  }

  public showDisplayDocument(files, deleteButton, fromData) {
    if (this.change) {
      this.$refs.dfl["setInitialValuesForLocalFiles"](files, null);
    } else {
      if (this.fileChangeCTC) {
        this.$refs.dfl["setInitialValuesForLocalFiles"](files, true, fromData);
      } else {
        this.$refs.dfl["setInitialValuesForShowingSamplesWithDeleteButton"](
          files,
          deleteButton,
          fromData
        );
      }
    }
  }

  /**********************************************************************************
   *         Display Files For Borrower and Third party condition and calling an    *
   *         Api fetchSelectedDocumentForCondition which fetch selected file        *
   *         and comment for that particular file                                   *
   **********************************************************************************/
  public async displayDocumentForCondition(documentId, fileHistoryId) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchSelectedDocumentForCondition",
        {
          documentId: documentId,
          loanTxnId: this.$route.query.id,
          fileHistoryId: fileHistoryId
        });
      let document = response.data.document;
      if (document.status == "Uploaded") {
        this.$refs.dfl["setInitialValues"](
          this.documents,
          [],
          document.reviewDoc,
          true,
          this.$route.query.id
        );
      } else {
        this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](
          document.comment,
          response.data.files,
          null
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  public checkFileHistory(documentId) {
    let arr = [];
    this.documents.forEach((doc, i) => {
      if (doc._id == documentId) {
        arr = doc.fileHistory;
      }
    });
    return arr;
  }

  public async closeModalBorrowerCondition() {
    this.$modal.hide("borrowerConditionModal");
  }

  public async closeModalThirdPartyCondition() {
    this.$modal.hide("thirdPartyConditionModal");
  }

  public async fileChanger(data, index) {
    this.fileUploadedFrom = [];
    this.fileUploadedFrom.push(data);
    this.fileUploadedFrom.push(index);
  }

  public async fileUpload(event) {
    let loader = this.$loading.show();
    try {
      if (event.target.files.length == 0) {
        loader.hide();
        return;
      }
      let files = [];
      Object.values(event.target.files).forEach(f => {
        files.push(f);
      });
      let path = "Borrower/" + this.$route.query.id + "/UWTask/";
      let formData = new FormData();
      formData.append("path", JSON.stringify(path));
      files.forEach(f => {
        formData.append("file", f);
      });
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/getFileUploadedPathForCA",
        formData);
      let localFiles = [];
      response.data.forEach(element => {
        this[this.fileUploadedFrom[0]][
          this.fileUploadedFrom[1]
        ].attachments.push(element);
        this.commentAttachs++;
        localFiles.push({ path: element.path });
      });
      this.$store.dispatch("setLocalFiles", localFiles);
      loader.hide();
    } catch (error) {
      loader.hide();
      console.log(error);
    }
  }

  public async openThirdPartyConditionModal(index, check) {
    this.modalIndex1 = index;
    if (check == "Add") {
      this.addCommentThirdPartyCondition();
      if (this.firstThirdPartyComment == true) {
        this.firstThirdPartyComment = false;
        this.commentThirdPartyCondition.shift();
      }
      let commentIndex = (this.commentIndex1 =
        this.commentThirdPartyCondition.length - 1);
      this.thirdPartyCondition[index].commentIndex = commentIndex;
      this.commentAttachs = 0;
    } else {
      this.commentIndex1 = this.thirdPartyCondition[index].commentIndex;
      this.commentAttachs = this.commentThirdPartyCondition[
        this.commentIndex1
      ].attachments.length;
    }
    this.$modal.show("thirdPartyConditionModal");
  }

  public async saveBorrowerConditionComment() {
    this.$modal.hide("borrowerConditionModal");
  }

  public async uploadSample(event) {
    let loader = this.$loading.show();
    try {
      if (event.target.files.length == 0) {
        loader.hide();
        return;
      }
      let files = [];
      Object.values(event.target.files).forEach(f => {
        files.push(f);
      });
      let path = "Borrower/" + this.$route.query.id + "/LoanDocuments/";
      let formData = new FormData();
      formData.append("path", JSON.stringify(path));
      files.forEach(f => {
        formData.append("file", f);
      });
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/getFileUploadedPath",
        formData);
      this.commentSampleAttachment = [];
      let localFiles = [];
      response.data.forEach(element => {
        this.commentSampleAttachment.push({ path: element.path });
        localFiles.push({ path: element.path });
      });
      this.$store.dispatch("setLocalFiles", localFiles);
      loader.hide();
    } catch (error) {
      loader.hide();
      console.log(error);
    }
  }

  public async saveThirdPartyConditionComment() {
    this.$modal.hide("thirdPartyConditionModal");
  }
  public async submitAndCompleted(event) {
    //  conditional approval
    try {
      let formData = new FormData();

      if (this.completed) {
        this.inProcessComplete = true;
        let valid = await this.$validator.validateAll("uwTask");
        if (!valid) {
          if (this.caHasFloatRate == "Lock Rate") {
            if (
              this.caRateLockDate == null ||
              this.updatedRateExpirationDate == null
            ) {
              this.caDateError = true;
            }
          }
          this.errorComment = true;
          this.inProcessComplete = false;
          return;
        }
        let borrowerCondition = false;
        this.borrowerCondition.forEach((d, i) => {
          if (d.conditionName != null) {
            if (!d.hasApprovedToSubmit) {
              borrowerCondition = true;
              this.inProcessComplete = false;
              return;
            }
          }
        });
        this.thirdPartyCondition.forEach((d, i) => {
          if (d.conditionName != null) {
            if (!d.hasApprovedToSubmit) {
              borrowerCondition = true;
              this.inProcessComplete = false;
              return;
            }
          }
        });
        if (borrowerCondition) {
          this.$snotify.error("To complete, All conditions should be approved");
          this.inProcessComplete = false;
          return;
        }
      } else {
        this.inProcessSave = true;
      }
      this.errorComment = false;
      let $thirdPartyCondition = JSON.parse(
        JSON.stringify(this.thirdPartyCondition)
      );
      for (let i = 0; i < $thirdPartyCondition.length; i++) {
        if ($thirdPartyCondition[i].companyTitle == "Other") {
          $thirdPartyCondition[i].companyTitle =
            $thirdPartyCondition[i].otherCompanyTitle;
        }
        delete $thirdPartyCondition[i].otherCompanyTitle;
      }

      //***************************
      this.loanAudit.loanTxnId = this.$route.query.id;
      this.loanAudit.source = "UW Condition Approval";
      this.loanAudit.activity.closingDate = this.closingDateConditional;
      this.loanAudit.activity.rateType = this.caHasFloatRate;
      this.loanAudit.activity.rateLockExpirationDate = this.updatedRateExpirationDate;
      this.loanAudit.activity.rateLockDate = this.caRateLockDate;
      this.loanAudit.activity.extendedDate = this.isExtended
        ? this.extendedDate
        : null;

      //***************************

      let taskObj = {
        borrowerCondition: this.borrowerCondition,
        thirdPartyCondition: $thirdPartyCondition,
        hasContactedBorrower: this.hasCAContactedBorrower,
        hasDisclosuresInitiated: this.hasDisclosuresInitiated,
        submittedToUnderwriter: this.submittedToUnderwriter,
        isActiveComment: this.isActiveCommentConditional,
        stampedApprovalDate: this.stampedApprovalDate,
        closingDate: this.closingDateConditional,
        selectedRateType: this.caHasFloatRate,
        updatedRateExpirationDate: this.updatedRateExpirationDate,
        rateLockDate: this.caRateLockDate,
        hasFloatRate: this.caHasFloatRate,
        isExtended: this.isExtended,
        extendedDate: this.extendedDate
      };
      let toUsers = [
        {
          type: this.originatorModelName,
          userId: this.addedBy
        }
      ];
      /**
       * sending condition approval message to broker on save
       */
      let sendSmsNotification = null;
      if (this.sendSmsToBroker || this.sendSmsToMlo) {
        let sms = ` ${this.borrowerName}
          Loan Status Update: Approval Pending Conditions \n${this.borrowerName} Loan Status Update: Closing Disclosure sent`;
        sendSmsNotification = this.sendConditionApprovalStatusSmsToBroker(
          sms,
          this.loanTxnId
        );
      }
      let data = {
        taskObj: taskObj,
        sendSmsNotification: sendSmsNotification,
        loanAudit: this.loanAudit,
        loanTxnId: this.$route.query.id,
        check: this.completed,
        commentConditionalApproval: this.commentConditionalApproval,
        commentChange: this.commentChange,
        fileChange: this.fileChange,
        fromUserId: this.$userId,
        fromType: this.$userType,
        toUsers: JSON.stringify(toUsers)
      };
      Object.entries(this.fileConditional).forEach((f: any) => {
        formData.append("file", f[1]);
      });
      if (this.removeConditionCAArray.length > 0) {
        data["removeCondition"] = this.removeConditionCAArray;
      }
      formData.append("data", JSON.stringify(data));
      this.$store.dispatch("emptyLocalFiles");

      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/UWResponseConditionalApproval",
        formData);
      if (response.data.text == "Completed") {
        this.$snotify.info("Task has been already closed", { timeout: 999999 });
        this.$router.push({
          path: "dashboard"
        });
      } else {
        this.$snotify.success(response.data.message);
        this.borrowerCondition = [];
        this.thirdPartyCondition = [];
        this.stampedApprovalDate = "";
        this.suspendedBorrowerCondition = [];
        this.suspendedThirdPartyCondition = [];
        this.commentBorrowerCondition = [];
        this.commentThirdPartyCondition = [];
        this.addBorrowerCondition();
        this.addThirdPartyCondition();
        this.addCommentThirdPartyCondition();
        this.fetchTaskDetails();
        if (this.completed == true || this.completed == false) {
          this.inProcessSave = false;
          this.inProcessComplete = false;

          this.$router.push({
            path: "dashboard",
            query: { id: data.loanTxnId }
          });
        }
      }
      this.caDateError = false;
    } catch (error) {
      this.caDateError = false;
      this.inProcessSave = false;
      this.inProcessComplete = false;
      console.log(error);
    }
  }
  public sendConditionApprovalStatusSmsToBroker(sms, loanTxnId) {
    try {
      let toUsersData = [
        {
          number: PHONE_EXT + this.brokerPhone.replace(/[()-\s]/g, ""),
          userId: this.brokerId,
          type: "Broker"
        }
      ];
      if (this.mloId) {
        toUsersData.push({
          number: PHONE_EXT + this.mloPhone.replace(/[()-\s]/g, ""),
          userId: this.mloId,
          type: "MLO"
        });
      }

      let sendSmsNotification = {
        message: sms,
        fromUserId: this.$userId,
        fromType: "Wemlo Processor",
        loanTxnId: this.loanTxnId,
        toUsers: toUsersData,
        wemloNumber: null
      };
      return sendSmsNotification;
    } catch (error) {}
  }
  // End of UW Response Conditional Approval

  public check() {
    if (this.uwTaskSelect == "Clear To Close") {
      this.denialShow = false;
      this.show = true;
      this.onConditionalApproval = false;
      this.onSuspended = false;
      this.status = this.uwTaskSelect;
    } else if (this.uwTaskSelect == "Denial") {
      this.show = false;
      this.denialShow = true;
      this.onSuspended = false;
      this.onConditionalApproval = false;
      this.status = this.uwTaskSelect;
    } else if (this.uwTaskSelect == "Conditional Approval") {
      this.show = false;
      this.denialShow = false;
      this.onSuspended = false;
      this.onConditionalApproval = true;
      this.status = this.uwTaskSelect;
    } else if (this.uwTaskSelect == "Suspended") {
      this.show = false;
      this.denialShow = false;
      this.onConditionalApproval = false;
      this.onSuspended = true;
      this.status = this.uwTaskSelect;
    }
  }
  public async selectFile(event) {
    if (event.target.files.length == 0) return;
    this.files = [];
    Object.values(event.target.files).forEach(element => {
      this.files.push(element);
    });
    if (this.files.length > 0) {
      this.fileChangeCTC = true;
      this.viewFileHide = false;
      this.isWemloInvoiceCtc = true;
    }
  }
  // Assigning the comment and attachment
  public fileUploadsConditionalApproval(files) {
    this.fileConditional = files;
    if (files.length > 0) {
      this.fileChange = true;
    }
  }
  public fileUploadsSuspended(files) {
    this.fileSuspended = files;
    if (files.length > 0) {
      this.fileChange = true;
    }
  }
  public fileUploadsClearToClose(files) {
    this.commentFiles = files;
    if (files.length > 0) {
      this.fileChange = true;
    }
  }
  public fileUploadsDenial(files) {
    this.fileDenial = files;
    if (files.length > 0) {
      this.fileChange = true;
    }
  }
  public async assignCommentConditional(comment) {
    this.commentConditionalApproval = comment;
    this.commentChange = true;
  }
  public async assignCommentSuspended(comment) {
    this.commentSuspended = comment;
    this.commentChange = true;
  }
  public async assignCommentClearToClose(comment) {
    this.comment = comment;
    this.commentChange = true;
  }
  public async assignCommentDenial(comment) {
    this.commentDenial = comment;
    this.commentChange = true;
  }
  // End of Assigning comment and Attachment
  public async submit() {
    try {
      !this.save
        ? (this.inProcessComplete = true)
        : (this.inProcessSave = true);
      let formData;
      let loanTxnId: any = this.$route.query.id;
      //submit Data of Clear To Close
      let formValid = await this.$validator.validateAll("clearToClose");
      if (!this.save) {
        if (!formValid) {
          if (
            (this.ctcHasFloatRate == "Lock Rate" &&
              (!this.updatedRateExpirationDate || !this.ctcRateLockDate)) ||
            (this.isExtended && !this.extendedDate)
          ) {
            this.ctcDateError = true;
          }
          if (this.hasContactedBorrower != true) {
            this.errorMessage.hasContactedBorrower = true;
          }
          if (this.hasContactedCompany != true) {
            this.errorMessage.hasContactedCompany = true;
          }
          if (this.hasClosingInitiated != true) {
            this.errorMessage.hasClosingInitiated = true;
          }
          if (this.hasContactedMlo != true) {
            this.errorMessage.hasContactedMlo = true;
          }
          if (!this.isClosingDate) {
            this.errorMessage.isClosingDate = true;
          }
          if (this.files.length == 0) {
            this.errorMessage.files = true;
          }
          if (this.commentFiles.length == 0) {
            this.errorMessage.commentFiles = true;
          }
          if (this.ctcHasFloatRate) {
            this.errorMessage.ctcHasFloatRate = null;
          }
          this.inProcessSave = false;
          this.inProcessComplete = false;
          return;
        }
      }
      this.errorMessage.hasContactedBorrower = false;
      this.errorMessage.hasContactedCompany = false;
      this.errorMessage.hasClosingInitiated = false;
      this.errorMessage.hasContactedMlo = false;
      this.errorMessage.isClosingDate = false;
      this.errorMessage.files = false;
      this.errorMessage.updatedRateExpirationDate = false;
      this.errorMessage.ctcHasFloatRate = false;
      this.errorMessage.ctcRateLockDate = false;

      this.errorMessage.caRateLockDate = false;

      formData = new FormData();
      if (this.isExtended != true) {
        this.extendedDate = null;
      }

      this.files.forEach((element: any) => {
        formData.append("file", element);
      });

      let commentFilesNames = [];
      this.commentFiles.forEach(e => {
        commentFilesNames.push(e.name);
        formData.append("file", e);
      });

      //***************************
      this.loanAudit.loanTxnId = this.$route.query.id;
      this.loanAudit.source = "UW Clear To Close";
      this.loanAudit.activity.closingDate = this.isClosingDate;
      this.loanAudit.activity.rateType = this.ctcHasFloatRate;
      this.loanAudit.activity.rateLockExpirationDate = this.updatedRateExpirationDate;
      this.loanAudit.activity.rateLockDate = this.ctcRateLockDate;
      this.loanAudit.activity.extendedDate = this.extendedDate;

      //***************************

      let toUsers = [
        {
          type: this.originatorModelName,
          userId: this.addedBy
        }
      ];
      formData.append("fromUserId", this.$userId);
      formData.append("fromType", this.$userType);
      formData.append("toUsers", JSON.stringify(toUsers));
      formData.append(
        "hasContactedCompany",
        JSON.stringify(this.hasContactedCompany)
      );
      formData.append(
        "hasContactedBorrower",
        JSON.stringify(this.hasContactedBorrower)
      );
      formData.append(
        "hasClosingInitiated",
        JSON.stringify(this.hasClosingInitiated)
      );
      formData.append("isExtended", JSON.stringify(this.isExtended));
      formData.append("isClosingDate", this.isClosingDate);
      formData.append("hasContactedMlo", JSON.stringify(this.hasContactedMlo));
      formData.append("loanTxnId", loanTxnId);
      formData.append("save", this.save);
      formData.append("brokerId", this.brokerId);
      formData.append("userType", this.$userType);
      formData.append("status", JSON.stringify(this.status));
      formData.append("extendedDate", JSON.stringify(this.extendedDate));
      formData.append("comment", this.comment);
      formData.append(
        "isActiveComment",
        JSON.stringify(this.isActiveCommentClearToClose)
      );
      formData.append("fileChange", JSON.stringify(this.fileChange));
      formData.append("commentChange", JSON.stringify(this.commentChange));
      formData.append("commentAttachment", JSON.stringify(commentFilesNames));
      formData.append("hasFloatRate", this.ctcHasFloatRate);
      formData.append("rateLockDate", this.ctcRateLockDate);
      formData.append("selectedRateType", JSON.stringify(this.ctcHasFloatRate));
      formData.append(
        "updatedRateExpirationDate",
        JSON.stringify(this.updatedRateExpirationDate)
      );
      formData.append("loanAudit", JSON.stringify(this.loanAudit));

      if (this.sendSmsToBroker || this.sendSmsToMlo){
        let sms = ` ${this.borrowerName}
          Loan Status Update: Approval Pending Conditions`;
        let sendSmsNotification = this.sendConditionApprovalStatusSmsToBroker( sms, this.loanTxnId );
        formData.append('sendSmsNotification', JSON.stringify(sendSmsNotification));
      }


      // if (this.commentFiles.length > 0) {
      //   formData.append("commentAttachment", JSON.stringify(this.commentFiles));
      // }
      formData.append(
        "isWemloInvoiceCtc",
        JSON.stringify(this.isWemloInvoiceCtc)
      );
      formData.append("newCountFiles", this.newFilesCount);
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/uwTask",
        formData);
      if (response.data.text == "Completed") {
        this.$snotify.info("Task has been already closed", { timeout: 999999 });
        this.$router.push({
          path: "dashboard"
        });
      } else if (response.data.message == "success") {
        this.$snotify.clear();
        this.$snotify.success("Data submitted");
      } else {
        this.inProcessSave = false;
        this.inProcessComplete = false;
        this.$snotify.error("Something went wrong, Please try again");
      }

      if (this.save == false) {
        this.hasContactedBorrower = null;
        this.hasContactedCompany = null;
        this.hasClosingInitiated = null;
        this.hasContactedMlo = null;
        this.isExtended = true;
        this.files = [];
        this.isClosingDate = null;
        this.extendedDate = null;
        this.comment = null;
      }
      if ((this.sendSmsToBroker || this.sendSmsToMlo) && !this.save) {
        let sms = `${this.borrowerName} 
        Loan Status Update:  CLEAR TO CLOSE!
        Updated Closing Date ${moment(this.updatedClosingDate).format(
          "dddd, MMMM D, YYYY"
        )}`;
        this.sendStatusSmsToBroker(sms, this.loanTxnId);
      }
      // if (this.hasClosingInitiated && !this.save) {
      //   let sms = `${this.borrowerName} 
      //   Loan Status Update:  Closing Disclosure has been send}`;
      //   this.sendStatusSmsToBroker(sms, this.loanTxnId);
      // }

      this.$router.push({ path: "dashboard" });
    } catch (error) {
      this.inProcessSave = false;
      this.inProcessComplete = false;
      console.log(error);
    }
  }
  public async addDenial(event) {
    try {
      !this.save
        ? (this.inProcessComplete = true)
        : (this.inProcessSave = true);
      let formValid = await this.$validator.validateAll("denialForm");
      if (!formValid && this.save != true) {
        if (this.hasContactedBorrower != true) {
          this.errorMessage.hasContactedBorrower = true;
        }
        if (this.hasContactedMlo != true) {
          this.errorMessage.hasContactedMlo = true;
        }
        if (this.hasUploadDenial != true) {
          this.errorMessage.UploadDenial = true;
        }
        this.inProcessComplete = false;
        return;
      }
      this.errorMessage.hasContactedBorrower = false;
      this.errorMessage.hasContactedMlo = false;
      this.errorMessage.UploadDenial = false;

      let formData = new FormData();
      let files = event.target[5].files;
      if (files.length > 0) this.fileChange = true;

      Object.entries(files).forEach((f: any) => {
        formData.append("file", f[1]);
      });
      let toUsers = [
        {
          type: this.originatorModelName,
          userId: this.addedBy
        }
      ];
      let denial = {
        loanTxnId: this.$route.query.id,
        status: "Inactive",
        hasContactedBorrower: this.denial.hasContactedBorrower,
        hasContactedMlo: this.denial.hasContactedMlo,
        hasUploadDenial: this.denial.hasUploadDenial,
        save: this.save,
        fileChange: this.fileChange,
        commentChange: this.commentChange,
        isActiveComment: this.isActiveCommentDenial,
        commentDenail: this.commentDenial,
        fromUserId: this.$userId,
        fromType: this.$userType,
        toUsers: JSON.stringify(toUsers)
      };
      formData.append("denial", JSON.stringify(denial));
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/uwTaskDenial",
        formData);
      if (response.data.text == "Completed") {
        this.$snotify.info("Task has been already closed", { timeout: 999999 });
        this.$router.push({
          path: "dashboard"
        });
      } else {
        this.$snotify.success("Data Submitted");
        if (this.save != true) {
          this.denial.hasContactedBorrower = null;
          this.denial.hasContactedMlo = null;
          this.denial.hasUploadDenial = null;
        }

        if (!this.save || this.save) {
          if ((this.sendSmsToBroker || this.sendSmsToMlo) && !this.save) {
            let sms = `${this.borrowerName} 
       User Deactivated`;
            this.sendStatusSmsToBroker(sms, this.loanTxnId);
          }
          this.$router.push({
            path: "dashboard",
            query: { id: denial.loanTxnId }
          });
        }
      }
      this.ctcDateError = false;
    } catch (error) {
      this.ctcDateError = false;
      this.inProcessSave = false;
      this.inProcessComplete = false;
      console.log(error);
    }
  }
  public async getUwTask() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getUwTask/" + this.$route.query.id);
      //fetch the Data to uwTask   Clear TO close
      if (this.$route.query.taskId == "19") {
        let uwTaskCtc = response.data.tasks.uwTask;
        Object.values(uwTaskCtc.clearToClose).forEach((uwTask: any) => {
          let x: any = new Date(uwTask.isClosingDate).getDate();
          if (x < 10) {
            x = "0" + x;
          }
          let y: any = new Date(uwTask.isClosingDate).getMonth() + 1;
          if (y < 10) {
            y = "0" + y;
          }

          let z = new Date(uwTask.isClosingDate).getFullYear();
          this.isActiveCommentClearToClose = uwTask.isActiveComment;
          this.hasContactedCompany = uwTask.hasContactedCompany;
          this.hasContactedBorrower = uwTask.hasContactedBorrower;
          this.hasClosingInitiated = uwTask.hasClosingInitiated;
          this.hasContactedMlo = uwTask.hasContactedMlo;
          this.isExtended = uwTask.isExtended;
          this.isClosingDate = uwTask.isClosingDate;
          //this.ctcRateLockDate = uwTask.rateLockDate;
          this.ctcHasFloatRate = uwTask.hasFloatRate;
          // ? z + "-" + y + "-" + x
          // : null;
          let a: any = new Date(uwTask.extendedDate).getDate();
          if (a < 10) {
            a = "0" + a;
          }
          let b: any = new Date(uwTask.extendedDate).getMonth() + 1;
          if (b < 10) {
            b = "0" + b;
          }
          let c = new Date(uwTask.extendedDate).getFullYear();
          this.extendedDate = uwTask.extendedDate
            ? c + "-" + b + "-" + a
            : null;
          this.files = uwTask.attachments;

          this.countFile = uwTask.attachments.length;
          if (this.files.length == 0) {
            this.viewFileHide = false;
          } else {
            this.viewFileHide = true;
          }
        });
        //  });
      } else if (this.$route.query.taskId == "10") {
        let uwTaskDenial = response.data.tasks.uwTask;
        //  Object.values(uwTaskDenial).forEach((element:any)=>{
        Object.values(uwTaskDenial.denial).forEach((denial: any) => {
          this.denial.hasContactedBorrower = denial.hasContactedBorrower;
          this.denial.hasContactedMlo = denial.hasContactedMlo;
          this.denial.hasUploadDenial = denial.hasUploadDenial;
          this.isActiveCommentDenial = denial.isActiveComment;
        });
        // })
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async displayFile(path, fileExtension) {
    this.fileType = fileExtension;
    this.fileToDisplay = path;
  }

  public async saveOrReject(index, check, data) {
    if (check == "Accept") {
      this[data][index].hasApprovedToSubmit = true;
      this[data][index].hasRejected = false;
      delete this[data][index].rejectComment;
      if (this[data + "Comments"]) {
        let count = true;
        this[data].forEach(check => {
          if (check.hasRejected) {
            count = false;
          }
        });
        if (count) this[data + "Comments"] = false;
      }
    }
    if (check == "Reject") {
      this[data][index].hasApprovedToSubmit = false;
      this[data][index].hasRejected = true;
      this[data][index].rejectComment = "";
      if (!this[data + "Comments"]) {
        this[data + "Comments"] = true;
      }
    }
  }

  public disablingToDate() {
    this.errorMessage.isClosingDate = false;
    let fromDate = new Date(this.isClosingDate);
    this.state.disabledDatesFrom.to = new Date(
      fromDate.setDate(fromDate.getDate() + 1)
    );
  }

  public changeValue(val, type) {
    if (val == "Float Rate") {
      this.restoreDisableDates();
      this.isExtended = false;
      this.caHasFloatRate = "Float Rate";
      this.ctcHasFloatRate = "Float Rate";
      this.updatedRateExpirationDate = null;
      this.extendedDate = null;
      if (type == "CA") {
        this.caRateLockDate = null;
      } else {
        this.ctcRateLockDate = null;
      }
    } else {
      this.caHasFloatRate = "Lock Rate";
      this.ctcHasFloatRate = "Lock Rate";
    }
  }
  async mounted() {
    this.restoreDisableDates();
    if (this.$route.query.taskId == "19") {
      await this.getUwTask();
      this.uwTaskSelect = "Clear To Close";
      this.showDropDown = false;
      this.show = true;
    } else if (this.$route.query.taskId == "10") {
      await this.getUwTask();
      this.uwTaskSelect = "Denial";
      this.showDropDown = false;
      this.denialShow = true;
    } else if (this.$route.query.taskId == "8") {
      await this.getUwTask();
      this.uwTaskSelect = "Conditional Approval";
      this.showDropDown = false;
      this.denialShow = false;
      this.onConditionalApproval = true;
    } else if (this.$route.query.taskId == "9") {
      this.uwTaskSelect = "Suspended";
      this.showDropDown = false;
      this.denialShow = false;
      this.onSuspended = true;
    }
    this.fetchTaskDetails();
  }

  public async uploadPendingConditionSample(event, origin, index) {
    let loader = this.$loading.show();
    try {
      if (event.target.files.length == 0) {
        loader.hide();
        return;
      }
      this[origin][index].samplesCount = null;
      this[origin][index].samplesCount = event.target.files.length;
      let files = [];
      Object.values(event.target.files).forEach(f => {
        files.push(f);
      });
      let path = "Borrower/" + this.$route.query.id + "/LoanDocuments/";
      let formData = new FormData();
      formData.append("path", JSON.stringify(path));
      files.forEach(f => {
        formData.append("file", f);
      });
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/getFileUploadedPath",
        formData);
      this[origin][index].samples = [];
      let localFiles = [];
      response.data.forEach(element => {
        this[origin][index].samples.push({ path: element.path });
        localFiles.push({ path: element.path });
      });
      this.$store.dispatch("setLocalFiles", localFiles);
      this[origin] = JSON.parse(JSON.stringify(this[origin]));
      loader.hide();
    } catch (error) {
      loader.hide();
    }
  }
  /************************************************************************************************
   *                                               Function                                        *
   *                                               For                                             *
   *                                               Removing already                                *
   *                                               Saved Condition Ends                            *
   ************************************************************************************************/

  public ifHaveToRemove(data, origin, index) {
    try {
      if (data.conditionName == "") {
        if (data.hasOwnProperty("documentId") && data.documentId != null) {
          this[origin].push({ id: data.documentId });
        }
        data.samples = [];
        data.samplesCount = 0;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public blank3rdParty(data, origin, index) {
    try {
      if (data.conditionName == "" && data.subCategory == null) {
        if (data.hasOwnProperty("documentId") && data.documentId != null) {
          this[origin].push({ id: data.documentId });
        }
        data.samples = [];
        data.samplesCount = 0;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**************************************************************************************************
   *                                    IF DOCUMENT NOT DISPLAYED THEN                               *
   **************************************************************************************************/
  public ifDocumentNotLoad(time: number) {
    this.dotLoader = true;
    try {
      setTimeout(() => {
        let checkLoader = document.getElementById("checkLoader");
        if (checkLoader != null) {
          let iframe: any = document.getElementById("checkElement");
          if (iframe.localName == "iframe") {
            iframe.src =
              "https://docs.google.com/gview?url=" +
              this.fileToDisplay +
              "&embedded=true";
          } else {
            iframe.src = this.fileToDisplay;
          }
          this.ifDocumentNotLoad(time + 1000);
        }
      }, time);
    } catch (error) {
      console.log(error);
      this.dotLoader = false;
    }
  }
  public disablingToCaDate() {
    if (this.caRateLockDate != null) {
      let fromDateOne = new Date(this.caRateLockDate);
      this.state.disabledDatesFromOne.to = new Date(
        fromDateOne.setDate(fromDateOne.getDate() + 1)
      );
    }

    if (this.updatedRateExpirationDate != null) {
      let fromDateTwo = new Date(this.updatedRateExpirationDate);
      this.state.disabledDatesFromTwo.to = new Date(
        fromDateTwo.setDate(fromDateTwo.getDate() + 1)
      );
    }
  }
  public disablingToCtcDate() {
    if (this.ctcRateLockDate != null) {
      let fromDateOne = new Date(this.ctcRateLockDate);
      this.state.disabledDatesFromOne.to = new Date(
        fromDateOne.setDate(fromDateOne.getDate() + 1)
      );
    }

    if (this.updatedRateExpirationDate != null) {
      let fromDateTwo = new Date(this.updatedRateExpirationDate);
      this.state.disabledDatesFromTwo.to = new Date(
        fromDateTwo.setDate(fromDateTwo.getDate() + 1)
      );
    }
  }

  public restoreDisableDates() {
    let date = new Date().setDate(new Date().getDate() - 1);
    this.state = {
      disabledDatesFrom: {
        to: new Date(date)
      },
      disabledDatesFromOne: {
        to: new Date(date)
      },
      disabledDatesFromTwo: {
        to: new Date(date)
      },
      disabledDatesTo: {
        to: new Date(date)
      }
    };
    return true;
  }

  async beforeMount() {
    this.fetchTaskDetails();
    this.addBorrowerCondition();
    this.addThirdPartyCondition();
    this.addCommentThirdPartyCondition();
    window.addEventListener("beforeunload", function(e) {
      let data = JSON.parse(localStorage["state.WeMLO"]);
      if (data.removeLocalFiles.length > 0) {
        store.dispatch("removeLocalFiles", data.removeLocalFiles);
      }
    });
  }

  /**************************************************************************************************
   *                                    IF FILES NOT LOADED IN DATABASE                              *
   **************************************************************************************************/
  async beforeDestroy() {
    let data = JSON.parse(localStorage["state.WeMLO"]);
    if (data.removeLocalFiles.length > 0) {
      store.dispatch("removeLocalFiles", data.removeLocalFiles);
    }
  }
}
