

import { Component } from "vue-property-decorator";
import AddComment from "@/views/AddComment.vue";
import { Hoi } from "@/models/tasks.model";
import { BASE_API_URL,EventBus } from "../../../../../config";
import Axios from "axios";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";


@Component({ components: { AddComment } })
export default class HoiComponent extends mixins(PipeLineSocketMixin, CommonMixin) {
  public hoi = new Hoi();
  public pipeLineHeaderData: any = {};
  public loanTxnId = null;
  public taskNameForRoom = 'hoi';

  private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "HOI", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/HOI.mp4' });
  }
  public async fetchHoiTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/fetchHoiTaskDetails",
        { loanTxnId: this.loanTxnId });

      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.hoi = response.data.hoi;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public async submit(ifSave) {
    try {
      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment["validateComment"](ifSave);
      if (
        (ifSave ? false : !(await this.$validator.validateAll())) ||
        !validComment
      )
        return;
      this.$store.state.wemloLoader = true;
      let formData = new FormData();

      formData.append("loanTxnId", this.loanTxnId);

      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.hoi.commentData.comment,
        showCommentToBroker: this.hoi.commentData.showCommentToBroker
      };
      if (
        this.hoi.commentData.commentAttachment.length > 0 &&
        !this.hoi.commentData.commentAttachment[0].hasOwnProperty("path")
      )
        this.hoi.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.hoi.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.hoi.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));

      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.hoi.options));

      let response = await Axios.post(
        BASE_API_URL + "additionalTask/hoi",
        formData);

      this.$snotify[
        response.data.status == 200
          ? "success"
          : response.data.status == 403
          ? "info"
          : "error"
      ](response.data.message);

      if (response.data.status == 200) {
        this.saveEvent();
      }

      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public removeInfectedFile(filename: string): void {
    // check if comment attachment contains infected filename
    if (
      this.hoi.commentData.commentAttachment.length > 0 &&
      !this.hoi.commentData.commentAttachment[0].hasOwnProperty("path")
    )
    {
      this.hoi.commentData.commentAttachment = this.hoi.commentData.commentAttachment.filter(file => (file.name !== filename));
    }
  }

  mounted() {
    this.loanTxnId = this.$route.query.id;
    this.fetchHoiTaskDetail();
  }
}
