

import { Component, Vue } from "vue-property-decorator";
import { PendingFunding } from "@/models/tasks.model";
import AddComment from "@/views/AddComment.vue";
import Axios from "axios";
import { BASE_API_URL,EventBus } from "../../../../config";


@Component({ components: { AddComment } })
export default class PendingFundingComponent extends Vue {



  public pendingFundingData = new PendingFunding();
  public pipeLineHeaderData: any = {};
  public loanTxnId = null;

  // private playWeLearnVideo() {
  //   EventBus.$emit("openWeLearn", { taskName: "Pending Funding", videoUrl: null });
  // }

  public async submit(ifSave) {
    try {


      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment['validateComment'](ifSave);


      if ((ifSave ? false : !await this.$validator.validateAll() ) || !validComment)
        return;
      if(!ifSave&&this.pendingFundingData.additionalTaskCount>0){
        this.$modal.show('warning');
        return;
      }
      /**
       * Taking Formdata for sending files and data
       */
      this.$store.state.wemloLoader = true;
      let formData = new FormData();


      // Appending loanTxnId here
      formData.append("loanTxnId", this.loanTxnId);
      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.pendingFundingData.commentData.comment,
        showCommentToBroker: this.pendingFundingData.commentData.showCommentToBroker
      }
      if (this.pendingFundingData.commentData.commentAttachment.length > 0 && !this.pendingFundingData.commentData.commentAttachment[0].hasOwnProperty("path"))
        this.pendingFundingData.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.pendingFundingData.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.pendingFundingData.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));


      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.pendingFundingData.options));
      /**
       * Finally sending request to server with formdata in body
       */

      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/pendingFundingV1",
        formData);
      this.$snotify[response.data.status == 200 ? "success" : response.data.status == 403 ? "info": "error"](response.data.message);
      this.$router.push({ path: "dashboard" });
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }
  public async fetchPendingFundingTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/fetchPendingFundingTaskDetail",
        {
          params: {
            loanTxnId: this.$route.query.id
          }
        }
      );
      // Redirect user to pending funding version 2 if he is working on loans newer than V3.
      if (response.data.loanInformation.loanVersion > 'V2') {  // Also handles, if someone hits the URL directly.
        this.$router.replace({ name: "Task Funding", query: {id: this.$route.query.id}});
      }
      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.pendingFundingData = response.data.pendingFundingData;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  async beforeMount() {
    this.loanTxnId = this.$route.query.id;
    await this.fetchPendingFundingTaskDetail();
  }


}
